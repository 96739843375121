<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled, flat }"
        :title="tooltip"
        @click="onEdit"
        @keydown.enter="onEdit"
        tabindex="0">
        <i class="bi bi-pencil"></i>
        <span v-if="!hideText">{{ $t("actionOpenInEditMode") }}</span>
    </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../models/song/song";
import { SongActionOptions } from "../../../interfaces/song-actions";
import { shouldHideAction } from "../../../utils/song-actions-utils";

export default defineComponent({
    name: "song-action-edit",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("edit", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.songActionOptions.disabled ?? false;
        },
        tooltip(): string {
            return this.$t("actionOpenInEditMode");
        },
    },
    methods: {
        onEdit(): void {
            if (this.disabled) {
                return;
            }
            this.$router.push({ name: "edit", query: { song: this.song.id } });
        },
    },
});
</script>
