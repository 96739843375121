import type { Song } from "../../../models/song/song";
import { TablatureEditor } from "../../song-editor/tablature-editor";
import type { EditorSelection } from "../../editor-selection/editor-selection";
import type { Tablature } from "../../../models/song/tablature";

export type CommandContext = {
    song: Song;
    editorSelection: EditorSelection;
};

export abstract class EditorCommand {
    protected song!: Song;
    protected editorSelection!: EditorSelection;
    protected tablatureEditor!: TablatureEditor;

    /** Executing this command will modify the song */
    public get modifiesSong(): boolean {
        return true;
    }

    protected get tablature(): Tablature {
        return this.song.tablature;
    }

    public execute(context: CommandContext): Promise<void> | void {
        this.preExecution(context);
        return this.onExecute();
    }

    public redo(context: CommandContext): Promise<void> | void {
        this.preExecution(context);
        return this.onRedo();
    }

    private preExecution(context: CommandContext): void {
        this.song = context.song;
        this.editorSelection = context.editorSelection;
        this.tablatureEditor = new TablatureEditor(this.song);
    }

    protected abstract onExecute(): void | Promise<void>;

    public onRedo(): void | Promise<void> {
        this.onExecute();
    }
}
