enum LockStatus {
    unlock,
    inProgress,
    retry,
}

type TaskList<T> = [() => Promise<T>, ...Array<(param: T) => Promise<T>>];
// type TaskList<T> = Array<(param: T) => Promise<T>>;

/**
 * Sequentially runs the provided callbacks in a Mutex Lock
 * If the lock is requested again, cancel pending tasks and restarts
 **/
export class TasksLock<T = void> {
    private lock = LockStatus.unlock;
    private tasks: TaskList<T>;
    private onError: (err: unknown) => void;

    constructor(tasks: TaskList<T>, onError: (err: unknown) => void) {
        this.tasks = tasks;
        this.onError = onError;
    }

    public run(): void {
        if (this.lock !== LockStatus.unlock) {
            this.lock = LockStatus.retry;
            return;
        }
        this.lock = LockStatus.inProgress;
        this.retryTasks();
    }

    private async retryTasks(): Promise<void> {
        await this.executeAsyncTasks();

        if (this.lock === LockStatus.retry) {
            this.lock = LockStatus.inProgress;
            return this.retryTasks();
        }

        this.lock = LockStatus.unlock;
    }

    private async executeAsyncTasks(): Promise<void> {
        const [firstTask, ...tasks] = this.tasks;

        try {
            let param = await firstTask();
            for (const cb of tasks) {
                if (this.lock !== LockStatus.inProgress) {
                    return;
                }

                param = await cb(param);
            }
        } catch (err) {
            this.onError(err);
            return;
        }
    }
}
