<template>
    <div class="viewer workspace">
        <share-header :song="song"></share-header>

        <main class="tablature" @dblclick="onDoubleClick">
            <editor-tags :song="song" :enable-inputs="false"></editor-tags>
            <editor-tablature :song="song" :can-edit="false"></editor-tablature>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ShareHeader from "./share-header/share-header.vue";
import EditorTags from "../editor/editor-tags.vue";
import EditorTablature from "../editor/editor-tablature.vue";
import type { Song } from "../../models/song/song";

export default defineComponent({
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    data() {
        return {
            canvasWidth: 0,
        };
    },
    components: {
        "share-header": ShareHeader,
        "editor-tags": EditorTags,
        "editor-tablature": EditorTablature,
    },
    mounted(): void {
        if (this.$store.zoom.isSupported) {
            this.$store.zoom.apply();
        }
    },
    methods: {
        onDoubleClick(_event: MouseEvent): void {
            // TODO: action on double click
        },
    },
});
</script>

<style lang="scss" scoped>
.viewer {
    background-color: var(--background-color-secondary);
    height: 100%;
    overflow: auto;
    padding-top: 122px;

    .tablature {
        align-content: flex-start;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .clear-space {
        height: 200px;
    }
}
</style>
