<template>
    <span class="loading-dots">{{ dots }}</span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        maxDots: {
            type: Number as PropType<number>,
            default: 3,
        },
        updateInterval: {
            type: Number as PropType<number>,
            default: 400,
        },
        dotCharacter: {
            type: String as PropType<string>,
            default: ".",
        },
    },
    data() {
        return {
            dots: "",
            dotsUpdateInterval: undefined as number | undefined,
        };
    },
    mounted(): void {
        this.dotsUpdateInterval = window.setInterval(() => {
            if (this.dots.length >= this.maxDots) {
                this.dots = "";
            } else {
                this.dots += this.dotCharacter;
            }
        }, this.updateInterval);
    },
    unmounted(): void {
        if (this.dotsUpdateInterval) {
            clearInterval(this.dotsUpdateInterval);
        }
        this.dotsUpdateInterval = undefined;
    },
});
</script>

<style lang="scss" scoped>
.loading-dots {
    display: inline-block;
    width: 12px;
}
</style>
