<template>
    <div class="viewer workspace" ref="viewerContainer">
        <viewer-header :song="song"></viewer-header>

        <main class="tablature" @dblclick="onDoubleClick">
            <editor-tags :song="song" :enable-inputs="false"></editor-tags>
            <editor-tablature :song="song" :can-edit="false"></editor-tablature>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ViewerHeader from "./viewer-header/viewer-header.vue";
import EditorTags from "../editor/editor-tags.vue";
import EditorTablature from "../editor/editor-tablature.vue";
import type { Song } from "../../models/song/song";

export default defineComponent({
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    data() {
        return {
            scrollBind: undefined as any,
        };
    },
    components: {
        "viewer-header": ViewerHeader,
        "editor-tags": EditorTags,
        "editor-tablature": EditorTablature,
    },
    mounted(): void {
        this.viewerContainer.scrollTop = this.$store.editor.scrollPosition;
        this.scrollBind = this.onScroll;
        this.viewerContainer.addEventListener("scroll", this.scrollBind);

        if (this.$store.zoom.isSupported) {
            this.$store.zoom.apply();
        }
    },
    unmounted(): void {
        this.viewerContainer.removeEventListener("scroll", this.scrollBind);
    },
    computed: {
        viewerContainer(): HTMLElement {
            return this.$refs.viewerContainer as any;
        },
    },
    methods: {
        onDoubleClick(_event: MouseEvent): void {
            if (!this.song.isEditable()) {
                return;
            }
            this.$router.push({ name: "edit", query: { song: this.song.id } });
        },
        onScroll(): void {
            this.$store.editor.scrollPosition = this.viewerContainer.scrollTop;
        },
    },
});
</script>

<style lang="scss" scoped>
.viewer {
    background-color: var(--background-color-secondary);
    height: 100%;
    overflow: auto;
    padding-top: 122px;
    padding-bottom: 250px;

    .tablature {
        align-content: flex-start;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .clear-space {
        height: 200px;
    }
}
</style>
