<template>
    <g>
        <template v-for="stringIndex in lines" :key="stringIndex">
            <line
                class="stave-row"
                :class="{ selected: isSelectedStave(stringIndex) }"
                :x1="stavePaddingLeft"
                :y1="getLineY(stringIndex - 1)"
                :x2="staveWidth"
                :y2="getLineY(stringIndex - 1)"
                :stroke-dasharray="strokeWidth" />
        </template>
    </g>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Geometry } from "../../services/renderer/geometry";
import type { EditorSelection } from "../../services/editor-selection/editor-selection";
import type { TablaturePosition } from "../../interfaces/tablature-position";

export default defineComponent({
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        rowIndex: {
            type: Number as PropType<number>,
            required: true,
        },
        canEdit: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    computed: {
        editorSelection(): EditorSelection {
            return this.$store.editor.selection as EditorSelection;
        },
        selectedPosition(): TablaturePosition | undefined {
            return this.editorSelection.getPosition();
        },
        selectedEventIndex(): number {
            return this.selectedPosition?.eventIndex ?? 0;
        },
        selectionRowIndex(): number {
            return this.geometry.getEventRow(this.selectedEventIndex);
        },

        lines(): number {
            return this.geometry.linesCount;
        },
        eventWidth(): number {
            return this.geometry.style.eventWidth;
        },
        strokeWidth(): number {
            return this.canEdit ? this.eventWidth / 8 : 0;
        },
        stavePaddingLeft(): number {
            return this.geometry.style.paddingLeft;
        },
        staveWidth(): number {
            return this.geometry.getStaveWidth();
        },
    },
    methods: {
        isSelectedStave(stringIndex: number): boolean {
            const hasSelection = Boolean(this.selectedPosition);
            const hasRangeSelection = this.editorSelection.hasRange();
            const isSelectedRow = this.rowIndex - 1 === this.selectionRowIndex;
            const isSelectedStaveRow = stringIndex - 1 === this.selectedPosition?.stringIndex;
            return hasSelection && !hasRangeSelection && isSelectedRow && isSelectedStaveRow;
        },
        getLineX(): number {
            return this.geometry.style.paddingLeft;
        },
        getLineY(stringIndex: number): number {
            return this.geometry.event.getNotePoint(stringIndex).y;
        },
    },
});
</script>

<style lang="scss" scoped>
.stave-row {
    stroke: var(--editor-stave-color);
    stroke-width: 1;
    &.selected {
        stroke: var(--editor-stave-color-selected);
    }
}
</style>
