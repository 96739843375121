<template>
    <slot v-if="shouldDisplayContent"></slot>

    <div v-if="shouldDisplayLoading" class="loading-container">
        <div class="loading-feedback"></div>
        <div class="loading-message" v-if="loadingMessage">
            {{ loadingMessage }}
            <loading-dots></loading-dots>
        </div>
    </div>

    <slot name="error" v-if="error" class="error-container">
        <!-- This is the default renderer if no content for the error slot is provided -->
        <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>
    </slot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import LoadingDots from "./loading-dots.vue";
import { LoadingStatus } from "../../../common/constants";

export default defineComponent({
    props: {
        status: {
            type: Number as PropType<LoadingStatus>,
            required: true,
        },
        loadingMessage: {
            type: String as PropType<string>,
            default: "",
        },
        errorMessage: {
            type: String as PropType<string>,
            default: "",
        },
        showContentWhileReloading: {
            type: Boolean,
            default: true,
        },
        showLoadingWhileReloading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        "loading-dots": LoadingDots,
    },
    computed: {
        shouldDisplayContent(): boolean {
            return this.ready || (this.showContentWhileReloading && this.reloading);
        },
        shouldDisplayLoading(): boolean {
            return this.loading || (this.showLoadingWhileReloading && this.reloading);
        },
        shouldDisplayError(): boolean {
            return this.error;
        },
        loading(): boolean {
            return this.status === LoadingStatus.LOADING;
        },
        reloading(): boolean {
            return this.status === LoadingStatus.RELOADING;
        },
        ready(): boolean {
            return this.status === LoadingStatus.READY;
        },
        error(): boolean {
            return this.status === LoadingStatus.ERROR;
        },
    },
});
</script>

<style lang="scss" scoped>
.error-container,
.loading-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding-bottom: 15vh;

    .loading-feedback {
        height: 150px;
        position: relative;

        &:before,
        &:after {
            content: "";
            width: 15px;
            height: 15px;
            display: block;
            position: relative;
            margin: 10px auto;
            border-radius: 50%;
            background: currentColor;
            box-shadow:
                0 50px,
                0 100px;
            animation: left 1s infinite ease-in-out;
        }

        &:after {
            color: var(--primary-color);
            animation: right 1.1s infinite ease-in-out;
        }

        @keyframes right {
            0%,
            100% {
                transform: translate(-15px);
            }
            50% {
                transform: translate(15px);
            }
        }

        @keyframes left {
            0%,
            100% {
                transform: translate(15px);
            }
            50% {
                transform: translate(-15px);
            }
        }
    }

    .loading-message,
    .error-message {
        padding: 52px;
        white-space: nowrap;
    }
}
</style>
