<template>
    <div class="songs-summary">
        <template v-if="hasResults">
            <div v-if="hasSearch" class="notification search-results">
                <i class="bi bi-search"></i>
                <span>{{ $t("searchMatches", { count: resultsCount }) }}</span>
            </div>
        </template>
        <template v-else>
            <div v-if="hasSearch" class="notification no-search-results">
                <i class="bi bi-search"></i>
                <span>{{ $t("noSongsMatchSearch") }}</span>
            </div>
            <div v-else class="notification no-songs">
                <i class="bi bi-music-note"></i>
                <span v-if="isSyncing">{{ $t("syncInitialSongsLoad") }}</span>
                <span v-else
                    >{{ $t("noSongs") }}
                    <a v-if="showCreateSong" class="d-block" href="/create">{{ $t("createNewSong") }} </a></span
                >
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SyncStatus } from "../../../../common/constants";

export default defineComponent({
    props: {
        searchValue: {
            type: String,
            default: "",
        },
        resultsCount: {
            type: Number,
            default: 0,
        },
        showCreateSong: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasResults(): boolean {
            return this.resultsCount > 0;
        },
        hasSearch(): boolean {
            return this.searchValue !== "";
        },
        syncStatus(): SyncStatus {
            return this.$store.song.syncStatus;
        },
        isSyncing(): boolean {
            return this.syncStatus === SyncStatus.IN_PROGRESS;
        },
    },
});
</script>

<style lang="scss" scoped>
.songs-summary {
    .notification {
        border-left: solid 1px var(--text-color-light);
        color: var(--text-color-light);
        padding: 5px 10px;
        margin: 20px 0;
        margin-bottom: 30px;
        display: flex;
        gap: 8px;
    }
}
</style>
