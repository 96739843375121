<template>
    <page-wrapper :showCreateSong="false">
        <signin-header></signin-header>

        <main class="singup">
            <div class="container">
                <form @click.prevent class="sign-in row g-3" novalidate>
                    <div>
                        <p>{{ $t("logIn") }} {{ $t("syncStatusLoggedOut") }}</p>
                    </div>
                    <!-- <div class="form-group col-12">
                        <input
                            v-model="loginData.email"
                            type="email"
                            class="form-control"
                            :class="{ 'is-invalid': emailError || credentialsError }"
                            placeholder="Email address"
                            @input="onEmailChange" />
                        <div class="invalid-feedback">{{ emailError }}</div>
                    </div>
                    <div class="form-group col-12">
                        <input
                            v-model="loginData.password"
                            type="password"
                            class="form-control"
                            :class="{ 'is-invalid': passwordError || credentialsError }"
                            placeholder="Password"
                            @input="onPasswordChange" />
                        <div class="invalid-feedback">{{ passwordError || credentialsError }}</div>
                    </div>
                    <div class="form-group col-12 col-lg-6">
                        <button
                            class="w-100 btn btn-primary sing-in-with-email"
                            :class="{ disabled: !singInEnabled }"
                            :disabled="!singInEnabled"
                            @click="loginWithEmail">
                            Sign in
                        </button>
                    </div>
                    <div class="form-group col-12 col-lg-6">
                        <button
                            class="w-100 btn create-account"
                            :class="{ disabled: !singInEnabled }"
                            :disabled="!singInEnabled"
                            @click="signinWithEmail">
                            <span>Don't have an account?</span>
                            <span> Register </span>
                        </button>
                    </div>
                    <div class="divider col-12">
                        <button class="w-100 btn create-account" @click="forgotPassword">Forgot my password</button>
                    </div>
                    -->

                    <button
                        class="w-100 btn btn google"
                        :class="{ disabled: isLoginRequested }"
                        @click="login('google.com')">
                        <span
                            v-show="loginRequestedForProvider == 'google.com'"
                            class="spinner-border spinner-border-sm"></span>
                        <i class="bi bi-google"></i>
                        {{ $t("signInWithGoogle") }}
                    </button>
                    <button
                        class="w-100 btn microsoft"
                        :class="{ disabled: isLoginRequested }"
                        @click="login('microsoft.com')">
                        <span
                            v-show="loginRequestedForProvider == 'microsoft.com'"
                            class="spinner-border spinner-border-sm"></span>
                        <i class="bi bi-microsoft"></i>
                        {{ $t("signInWithMicrosoft") }}
                    </button>
                    <button class="w-100 btn apple" :class="{ disabled: isLoginRequested }" @click="login('apple.com')">
                        <span
                            v-show="loginRequestedForProvider == 'apple.com'"
                            class="spinner-border spinner-border-sm"></span>
                        <i class="bi bi-apple"></i>
                        {{ $t("signInWithApple") }}
                    </button>

                    <span class="singin-new-window-note">{{ $t("newWindowToSignIn") }}</span>
                </form>
                <div class="credentials-error" v-if="credentialsError">
                    <span>{{ credentialsError }} </span>
                </div>
            </div>
        </main>
    </page-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { api } from "../../apis/api";
import SigninHeader from "./signin-header/signin-header.vue";
import PageWrapper from "@components/page-wrapper/page-wrapper.vue";
import { FirebaseError } from "@firebase/util";
import { OauthProviderId } from "../../apis/auth-api";

export default defineComponent({
    components: {
        "page-wrapper": PageWrapper,
        "signin-header": SigninHeader,
    },
    data() {
        return {
            loginRequestedForProvider: "",
            loginData: {
                email: "",
                password: "",
            },
            emailError: "",
            passwordError: "",
            credentialsError: "",
        };
    },
    computed: {
        singInEnabled(): boolean {
            return (
                this.loginData.email !== "" && this.loginData.password !== "" && !this.emailError && !this.passwordError
            );
        },
        isLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        isLoginRequested(): boolean {
            return this.loginRequestedForProvider !== "";
        },
    },
    methods: {
        async login(provider: OauthProviderId): Promise<void> {
            this.credentialsError = "";
            this.loginRequestedForProvider = provider;
            try {
                await api.auth.oauthLogin(provider);
                this.$router.push({ name: "dashboard" });
            } catch (error) {
                this.credentialsError = this.errorFormatter(error);
            } finally {
                this.loginRequestedForProvider = "";
            }
        },
        errorFormatter(error: unknown): string {
            if (error instanceof FirebaseError) {
                if (
                    [
                        "auth/account-exists-with-different-credential",
                        "auth/popup-closed-by-user",
                        "auth/user-cancelled",
                    ].includes(error.code)
                ) {
                    return this.$t(error.code);
                }
            }
            return `${error}`;
        },
        // async loginWithEmail(): Promise<void> {
        //     if (!this.singInEnabled) return;

        //     const { error, data } = await api.auth.loginWithEmail({
        //         email: this.loginData.email,
        //         password: this.loginData.password,
        //     });

        //     if (error) {
        //         if (error.message.includes("credentials")) {
        //             this.credentialsError = error.message;
        //         }
        //         return;
        //     }
        //     this.$router.push({ name: "dashboard" });
        // },
        // async signinWithEmail(): Promise<void> {
        //     if (!this.singInEnabled) return;

        //     const { error, data } = await api.auth.signinWithEmail({
        //         email: this.loginData.email,
        //         password: this.loginData.password,
        //     });

        //     if (error) {
        //         if (error.message.includes("email")) {
        //             this.emailError = error.message;
        //         } else if (error.message.includes("Password")) {
        //             this.passwordError = error.message;
        //         }
        //         return;
        //     }

        //     this.$router.replace({ name: "confirm-email" });
        // },
        // onEmailChange(_event: Event): void {
        //     this.emailError = "";
        //     this.credentialsError = "";
        // },
        // onPasswordChange(_event: Event): void {
        //     this.passwordError = "";
        //     this.credentialsError = "";
        // },
        // async forgotPassword() {
        //     await Swal.fire({
        //         title: "Reset Password",
        //         input: "email",
        //         inputPlaceholder: "Enter your email address",
        //         showCancelButton: true,
        //         confirmButtonText: "Reset Password",
        //         showLoaderOnConfirm: true,
        //         allowOutsideClick: () => !Swal.isLoading(),
        //         preConfirm(email: string) {
        //             return api.auth.resetPassword(email).catch((error) => {
        //                 Swal.showValidationMessage(error);
        //             });
        //         },
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire({
        //                 title: `Reset password email sent`,
        //             });
        //         }
        //     });
        // },
    },
});
</script>

<style lang="scss" scoped>
.singup {
    padding-bottom: 90px;
    padding-top: 90px;

    .container {
        background-color: var(--background-color-secondary);
        align-items: stretch;
        flex-direction: column;
        padding: 30px;
        border-radius: 15px;

        .sign-in {
            margin: 0 auto;
            min-width: 250px;
            text-align: center;
            width: 60%;

            .create-account {
                text-align: center;
                display: flex;
                gap: 5px;
                justify-content: center;
                flex-wrap: wrap;
            }

            .btn {
                justify-content: center;
            }
        }

        .singin-new-window-note {
            font-size: 12px;
            color: var(--gray-500);
        }

        .credentials-error {
            text-align: center;
            color: var(--error-color-dark);
        }
        .btn.microsoft {
            background-color: #00a4ef;
            color: #ffffff !important;
        }
        .btn.apple {
            background-color: #7a7f81;
            color: #ffffff !important;
        }
        .btn.google {
            color: #ffffff !important;
            background-color: #db4437;
        }
    }
}
</style>
