<template>
    <div
        v-if="!isLoggedIn && !hasDismissedLoginWarning"
        class="login-warning alert alert-warning alert-dismissible"
        role="alert">
        <div class="d-flex gap-2">
            <i class="bi bi-person-circle"></i>
            <span
                ><a href="/signin">{{ $t("logIn") }}</a> {{ $t("syncStatusLoggedOut") }}</span
            >
            <button
                type="button"
                class="btn-close"
                aria-label="Dismiss"
                data-bs-dismiss="alert"
                @click="onLoginWarningDismiss"></button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { api } from "../../apis/api";

export default defineComponent({
    computed: {
        isLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        hasDismissedLoginWarning(): boolean {
            return api.localStorage.getBoolean("dismissedLoginWarning") === true;
        },
    },
    methods: {
        onLoginWarningDismiss(): void {
            api.localStorage.set("dismissedLoginWarning", true);
        },
    },
});
</script>

<style lang="scss" scoped>
.login-warning {
    margin-top: -15px;
}
</style>
