import { SongsApi } from "./songs-api";
import { initializeApp } from "firebase/app";
import { ApiConfig } from "./config";
import { AuthApi } from "./auth-api";
import { LocalStorage } from "./local-storage";
import { LogApi } from "./log";
import { LocalSongsApi } from "./local-songs-api";
import { RemoteSongsApi } from "./remote-songs-api";
import { SongSync } from "./song-sync";

const firebase = initializeApp(ApiConfig.firebaseConfig);

const authApi = new AuthApi(firebase);

const localSongsApi = new LocalSongsApi();
const remoteSongsApi = new RemoteSongsApi(authApi, firebase);
const songsApi = new SongsApi(localSongsApi, remoteSongsApi);

const syncApi = new SongSync({
    songsApi: songsApi,
    remote: remoteSongsApi,
    local: localSongsApi,
});

export const api = {
    songs: songsApi,
    auth: authApi,
    localStorage: new LocalStorage(),
    log: new LogApi(),
    syncApi: syncApi,
};
