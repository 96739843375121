<template>
    <div class="sidebar-content">
        <div class="links">
            <ul class="nav nav-pills flex-column mb-auto">
                <li>
                    <router-link class="btn nav-link" :to="{ name: 'dashboard' }">
                        <div class="link-name"><i class="bi bi-music-note-list"></i>{{ $t("linkSongs") }}</div>
                        <div class="songs-count">{{ songsCount }}</div>
                    </router-link>
                </li>
            </ul>
            <hr />

            <ul class="nav nav-pills flex-column mb-auto">
                <li>
                    <router-link class="btn nav-link" :to="{ name: 'archive' }">
                        <div class="link-name"><i class="bi bi-archive"></i>{{ $t("linkArchive") }}</div>
                        <div class="songs-count">{{ archivedSongsCount }}</div>
                    </router-link>
                </li>
                <li>
                    <router-link class="btn nav-link" :to="{ name: 'trash' }">
                        <div class="link-name"><i class="bi bi-trash"></i>{{ $t("linkTrash") }}</div>
                        <div class="songs-count">{{ trashedSongsCount }}</div></router-link
                    >
                </li>
            </ul>

            <hr />

            <ul class="nav nav-pills flex-column mb-auto">
                <li>
                    <router-link class="btn nav-link" :to="{ name: 'settings' }">
                        <div class="link-name"><i class="bi bi-gear"></i>{{ $t("linkSettings") }}</div>
                    </router-link>
                </li>
                <li>
                    <a class="btn nav-link" href="https://my-guitar-tabs.com/docs">
                        <div class="link-name"><i class="bi bi-question-circle"></i>{{ $t("linkManual") }}</div>
                    </a>
                </li>
                <li>
                    <a class="btn nav-link" href="https://my-guitar-tabs.com/contact">
                        <div class="link-name"><i class="bi bi-chat-square-text"></i>{{ $t("linkContactUs") }}</div>
                    </a>
                </li>
                <li v-if="showAndroidRatingLink">
                    <a class="btn nav-link" href="https://play.google.com/store/apps/details?id=com.my_guitar_tabs">
                        <div class="link-name"><i class="bi bi-google-play"></i>{{ $t("rateAppAndroid") }}</div>
                    </a>
                </li>
                <li v-if="shouldShowInstall">
                    <button class="btn nav-link" @click="install">
                        <div class="link-name"><i class="bi bi-download"></i>{{ $t("linkInstall") }}</div>
                    </button>
                </li>
            </ul>
        </div>
        <div class="icon">{{ $t("appName") }} {{ appVersion }}</div>
    </div>
</template>

<script lang="ts">
import { SongStatus } from "@common/song-status";
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        showAndroidRatingLink(): boolean {
            return this.$store.config.appVersion === "android";
        },
        songsCount(): number {
            return (
                this.$store.song.getAll(SongStatus.Default).length + this.$store.song.getAll(SongStatus.Pinned).length
            );
        },
        archivedSongsCount(): number {
            return this.$store.song.getAll(SongStatus.Archived).length;
        },
        trashedSongsCount(): number {
            return this.$store.song.getAll(SongStatus.Trashed).length;
        },
        shouldShowInstall(): boolean {
            return this.$store.install.shouldShow();
        },
        appVersion(): string {
            return APP_VERSION ? `v${APP_VERSION}` : "";
        },
    },
    methods: {
        install(): void {
            this.$store.install.prompt();
        },
    },
});
</script>

<style lang="scss" scoped>
.sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .nav-pills {
        margin-bottom: 0 !important;

        li {
            .nav-link {
                align-items: center;
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                display: flex;
                height: 53px;
                justify-content: space-between;
                width: 100%;

                .songs-count {
                    padding: 5px;
                }
            }
        }
    }

    i {
        margin-right: 5px;
    }
}
</style>
