import type { ChordModifier } from "../../../../../common/tablature-event";
import type { BendDownModifier, BendModifier, PreBendModifier } from "../../../../../common/note";
import { NoteModifier } from "../../../../../common/note";
import type { TablaturePosition } from "../../../../interfaces/tablature-position";
import {
    getEquivalentBend,
    getEquivalentBendDown,
    getEquivalentPreBend,
    isBendDownModifier,
    isBendModifier,
    isPreBendModifier,
} from "../../../../utils/modifiers/bend-modifiers-helpers";
import { EditorCommand } from "../editor-command";
import { isInArray } from "../../../../utils/is-in-array";

export class UpdateModifierCommand extends EditorCommand {
    private modifier: NoteModifier | ChordModifier;
    private position: TablaturePosition | undefined;

    constructor({ modifier, position }: { modifier: NoteModifier | ChordModifier; position?: TablaturePosition }) {
        super();
        this.modifier = modifier;
        this.position = position;
    }

    public onExecute(): void {
        if (this.isNoteModifier(this.modifier)) {
            const selectionPosition = this.getTargetPosition();
            if (!selectionPosition) {
                return;
            }

            this.executeNoteModifierUpdate(this.modifier, selectionPosition);
            this.handleCursorAfterModifier(selectionPosition);
            return;
        }
        return this.executeChordModifier(this.modifier);
    }

    private getTargetPosition(): TablaturePosition | undefined {
        if (!this.position && this.editorSelection.hasRange()) {
            return;
        }

        return this.position ? this.position : this.editorSelection.getPosition();
    }

    private handleCursorAfterModifier(previousPosition: TablaturePosition) {
        if (this.modifier === NoteModifier.hammerPull || this.modifier === NoteModifier.slide) {
            let targetEventIndex = previousPosition.eventIndex + 1;

            // modifiers "jump" one extra event for barlines
            if (this.tablature.getEventType(targetEventIndex) === "barline") {
                targetEventIndex += 1;
            }

            this.editorSelection.set({
                stringIndex: previousPosition.stringIndex,
                eventIndex: targetEventIndex,
            });
        }
    }

    private isNoteModifier(mod: NoteModifier | ChordModifier): mod is NoteModifier {
        return isInArray(Object.values(NoteModifier), mod);
    }

    private executeNoteModifierUpdate(modifier: NoteModifier, position: TablaturePosition): void {
        const noteModifiers = new Set(this.tablature.getNoteModifiers(position));

        if (isBendDownModifier(modifier)) {
            const currentModifier = this.getBendDownModifier(noteModifiers);
            if (currentModifier) {
                noteModifiers.delete(currentModifier);
            }
            const newModifier = this.calculateNextBendDownModifier(currentModifier);
            if (newModifier) {
                const modifierToAdd = this.handlePreviousBendUpModifier(position, currentModifier, newModifier);
                noteModifiers.add(modifierToAdd);
            }
        } else if (isBendModifier(modifier)) {
            const incompatibleModifier = this.getPreBendModifier(noteModifiers);
            if (incompatibleModifier) {
                noteModifiers.delete(incompatibleModifier);
            }
            const currentModifier = this.getBendUpModifier(noteModifiers);

            if (currentModifier) {
                noteModifiers.delete(currentModifier);
            }

            const newModifier = this.calculateNextBendUpModifier(currentModifier);
            if (newModifier) {
                const modifierToAdd = this.handleNextBendDownModifier(position, currentModifier, newModifier);
                noteModifiers.add(modifierToAdd);
            }
        } else if (isPreBendModifier(modifier)) {
            const incompatibleModifier = this.getBendUpModifier(noteModifiers);
            if (incompatibleModifier) {
                noteModifiers.delete(incompatibleModifier);
            }
            const currentModifier = this.getPreBendModifier(noteModifiers);
            if (currentModifier) {
                noteModifiers.delete(currentModifier);
            }

            const newModifier = this.calculateNextPreBendModifier(currentModifier);
            if (newModifier) {
                const modifierToAdd = this.handleNextBendDownModifier(position, currentModifier, newModifier);
                noteModifiers.add(modifierToAdd);
            }
        } else {
            if (noteModifiers.has(modifier)) {
                noteModifiers.delete(modifier);
            } else {
                noteModifiers.add(modifier);
            }
        }

        this.tablatureEditor.updateNoteModifiers(position, Array.from(noteModifiers));
    }

    private handleNextBendDownModifier(
        selectionPosition: TablaturePosition,
        currentModifier: BendModifier | PreBendModifier | undefined,
        newModifier: BendModifier | PreBendModifier
    ): BendModifier | PreBendModifier {
        const nextPosition = {
            eventIndex: selectionPosition.eventIndex + 1,
            stringIndex: selectionPosition.stringIndex,
        };
        const nextModifiers = new Set(this.tablature.getNoteModifiers(nextPosition));
        const nextBendDownModifier = this.getBendDownModifier(nextModifiers);
        if (nextBendDownModifier) {
            if (currentModifier) {
                // If a current modifier exists, update the next one (bend down)
                const bendDown = getEquivalentBendDown(newModifier);
                nextModifiers.delete(nextBendDownModifier);
                nextModifiers.add(bendDown);
                this.tablatureEditor.updateNoteModifiers(nextPosition, Array.from(nextModifiers));
                return newModifier;
            } else {
                // If no modifier exists, initialize the current one with the same value of the next one
                if (isPreBendModifier(newModifier)) {
                    return getEquivalentPreBend(nextBendDownModifier);
                }

                return getEquivalentBend(nextBendDownModifier);
            }
        }
        return newModifier;
    }
    private handlePreviousBendUpModifier(
        selectionPosition: TablaturePosition,
        currentModifier: BendDownModifier | undefined,
        computedModifier: BendDownModifier
    ): BendDownModifier {
        const previousPosition = {
            eventIndex: selectionPosition.eventIndex - 1,
            stringIndex: selectionPosition.stringIndex,
        };
        const previousNoteModifiers = new Set(this.tablature.getNoteModifiers(previousPosition));
        const previousBendUpModifier = this.getBendUpModifier(previousNoteModifiers);
        if (previousBendUpModifier) {
            if (currentModifier) {
                // If a current modifier exists, update the next one
                const bendDown = getEquivalentBend(computedModifier);
                previousNoteModifiers.delete(previousBendUpModifier);
                previousNoteModifiers.add(bendDown);
                this.tablatureEditor.updateNoteModifiers(previousPosition, Array.from(previousNoteModifiers));
                return computedModifier;
            } else {
                // If no modifier exists, update the current one with the next one
                return getEquivalentBendDown(previousBendUpModifier);
            }
        }
        return computedModifier;
    }

    private executeChordModifier(modifier: ChordModifier): void {
        const eventIndexes = this.editorSelection.getSelectedEventIndexes();

        const shouldUnset = this.allChordsHaveModifier(modifier, eventIndexes);

        if (shouldUnset) {
            return this.unsetAllChordModifiers(modifier, eventIndexes);
        }
        return this.setAllChordModifiers(modifier, eventIndexes);
    }

    private setAllChordModifiers(modifier: ChordModifier, eventIndexes: number[]): void {
        for (const eventIndex of eventIndexes) {
            this.tablatureEditor.setChordModifier(eventIndex, modifier);
        }
    }

    private unsetAllChordModifiers(modifier: ChordModifier, eventIndexes: number[]): void {
        for (const eventIndex of eventIndexes) {
            this.tablatureEditor.unsetChordModifier(eventIndex, modifier);
        }
    }

    private allChordsHaveModifier(modifier: ChordModifier, eventIndexes: number[]): boolean {
        for (const eventIndex of eventIndexes) {
            if (!this.tablature.chordHasModifier(eventIndex, modifier)) {
                return false;
            }
        }
        return true;
    }

    private getBendUpModifier(modifiers: Set<NoteModifier>): BendModifier | undefined {
        const bendModifiers = [
            NoteModifier.quarterBend,
            NoteModifier.halfBend,
            NoteModifier.bend,
            NoteModifier.doubleBend,
        ] as const;

        for (const modifier of bendModifiers) {
            if (modifiers.has(modifier)) {
                return modifier;
            }
        }
    }

    private getBendDownModifier(modifiers: Set<NoteModifier>): BendDownModifier | undefined {
        const bendModifiers = [
            NoteModifier.quarterBendDown,
            NoteModifier.halfBendDown,
            NoteModifier.bendDown,
            NoteModifier.doubleBendDown,
        ] as const;

        for (const modifier of bendModifiers) {
            if (modifiers.has(modifier)) {
                return modifier;
            }
        }
    }

    private getPreBendModifier(modifiers: Set<NoteModifier>): PreBendModifier | undefined {
        const bendModifiers = [
            NoteModifier.quarterPreBend,
            NoteModifier.halfPreBend,
            NoteModifier.preBend,
            NoteModifier.doublePreBend,
        ] as const;

        for (const modifier of bendModifiers) {
            if (modifiers.has(modifier)) {
                return modifier;
            }
        }
    }

    private calculateNextBendUpModifier(prevModifier: BendModifier | undefined): BendModifier | undefined {
        switch (prevModifier) {
            case NoteModifier.halfBend:
                return NoteModifier.bend;
            case NoteModifier.quarterBend:
                return NoteModifier.halfBend;
            case NoteModifier.bend:
                return undefined;
            case NoteModifier.doubleBend: // Double bend is deprecated
                return undefined;
            default:
                return NoteModifier.quarterBend;
        }
    }

    private calculateNextBendDownModifier(prevModifier: BendDownModifier | undefined): BendDownModifier | undefined {
        switch (prevModifier) {
            case NoteModifier.halfBendDown:
                return NoteModifier.bendDown;
            case NoteModifier.quarterBendDown:
                return NoteModifier.halfBendDown;
            case NoteModifier.bendDown:
                return undefined;
            case NoteModifier.doubleBendDown: // Double bend is deprecated
                return undefined;
            default:
                return NoteModifier.quarterBendDown;
        }
    }

    private calculateNextPreBendModifier(prevModifier: PreBendModifier | undefined): PreBendModifier | undefined {
        switch (prevModifier) {
            case NoteModifier.halfPreBend:
                return NoteModifier.preBend;
            case NoteModifier.quarterPreBend:
                return NoteModifier.halfPreBend;
            case NoteModifier.preBend:
                return undefined;
            case NoteModifier.doublePreBend: // Double bend is deprecated
                return undefined;
            default:
                return NoteModifier.quarterPreBend;
        }
    }
}
