<template>
    <div class="app-name">
        <a class="brand" href="https://my-guitar-tabs.com">
            <div class="logo">
                <img src="../../static/logo/logo.png" />
            </div>
            <b class="title">{{ $t("appName") }}</b>
        </a>
        <div v-if="!hideDescription" class="app-description text-muted">{{ $t("appDescription") }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        hideDescription: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style lang="scss" scoped>
.app-name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .brand {
        align-items: center;
        color: var(--text-color);
        display: flex;
        gap: 6px;
        margin-right: 1rem;
        min-width: 0;
        text-decoration: none;

        .logo {
            flex: 0 0 auto;
            height: 2rem;
            margin-right: 0.5rem;

            img {
                height: 100%;
            }
        }

        .title {
            flex: 1 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .app-description {
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
    }
}
</style>
