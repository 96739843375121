<template>
    <div class="sticky-sidebar sidebar" id="sticky-sidebar">
        <app-name class="sticky-header" :hideDescription="true"></app-name>
        <div class="py-3 sidebar-sticky">
            <sidebar-content></sidebar-content>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppName from "../app-name/app-name.vue";
import SidebarContent from "./content.vue";

export default defineComponent({
    components: {
        "app-name": AppName,
        "sidebar-content": SidebarContent,
    },
});
</script>

<style lang="scss" scoped>
.sticky-sidebar {
    display: none;
    height: 100%;

    @media (min-width: 1400px) {
        width: 280px;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
    }
    .sticky-header {
        background-color: var(--background-color-secondary);
        border-bottom: solid 1px var(--gray-300);
        height: 62px;
    }

    .sidebar-sticky {
        flex-grow: 1;
    }
}
</style>
