<template>
    <div class="version-container">
        <p>{{ $t("appName") }} {{ appVersion }}</p>
        <div v-if="needsReload">
            <p>New Version Available</p>
            <button @click="reloadApp" class="btn btn-warning">Reload</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        needsReload(): boolean {
            return this.$store.user.needReload;
        },
        appVersion(): string {
            return APP_VERSION ? `v${APP_VERSION}` : "";
        },
    },
    methods: {
        async reloadApp(): Promise<void> {
            await this.$store.user.reload();
        },
    },
});
</script>

<style lang="scss" scoped>
.version-container {
    text-align: center;
}
</style>
