<template>
    <page-wrapper>
        <main-header @searchChange="onSearchChange"></main-header>
        <songs :searchValue="searchValue" :modes="[pinnedStatus, defaultStatus]" :showCreateSong="true"></songs>
    </page-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SongStatus } from "@common/song-status";
import PageWrapper from "@components/page-wrapper/page-wrapper.vue";
import Songs from "@components/songs/songs.vue";
import MainHeader from "@components/main-header/main-header.vue";
import { getConfigStore } from "../../stores/config.store";

export default defineComponent({
    beforeRouteEnter(to, from, next) {
        // Cannot use this.* here as it is beforeEnter
        if (typeof to.query.appVersion === "string") {
            const configStore = getConfigStore();
            configStore.setAppVersion(to.query.appVersion);
        }

        next();
    },
    components: {
        "page-wrapper": PageWrapper,
        "main-header": MainHeader,
        songs: Songs,
    },
    data() {
        return {
            pinnedStatus: SongStatus.Pinned,
            defaultStatus: SongStatus.Default,
            searchValue: "",
        };
    },
    methods: {
        onSearchChange(value: string): void {
            this.searchValue = value;
        },
    },
});
</script>

<style lang="scss" scoped></style>
