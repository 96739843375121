import { SyncStatus } from "../../common/constants";
import type { Song } from "../models/song/song";
import { getSongStore } from "../stores/song.store";
import { getUserStore } from "../stores/user.store";
import { isOffline } from "../utils/is-offline";
import { TasksLock } from "../utils/tasks-lock";
import { filterTruthy, type ValueOf } from "../utils/utils";
import { api } from "./api";
import { ApiError } from "./errors";
import type { LocalSongsApi } from "./local-songs-api";
import type { RemoteSongsApi } from "./remote-songs-api";
import type { SongsApi } from "./songs-api";

export type SongTransactionLogRecord = {
    type: "update" | "delete";
    timestamp: number;
};

export type SongTransactionLog = Record<string, SongTransactionLogRecord>;

class SyncError extends Error {
    public status: SyncStatus;

    constructor(status: SyncStatus) {
        super();
        this.status = status;
    }
}

export class SongSync {
    private remote: RemoteSongsApi;
    private local: LocalSongsApi;

    private syncLock: TasksLock<SyncStatus>;

    constructor({ songsApi, remote, local }: { songsApi: SongsApi; remote: RemoteSongsApi; local: LocalSongsApi }) {
        this.remote = remote;
        this.local = local;
        songsApi.addEventListener("update", (event) => {
            if (event instanceof CustomEvent) {
                const songId = event.detail.id;
                this.addTransactionLogRecord(songId, "update");
            }
        });
        songsApi.addEventListener("delete", (event) => {
            if (event instanceof CustomEvent) {
                const songId = event.detail.id;
                this.addTransactionLogRecord(songId, "delete");
            }
        });

        this.syncLock = this.createSyncTasks();
    }

    public haveUnsyncedSongs(): boolean {
        const transactionLog = this.getTransactionLog();
        return Object.entries(transactionLog).length > 0;
    }

    public async onLogout() {
        await this.clearLocalData();
        const songStore = getSongStore();
        await songStore.fetchSongs();
    }

    public async sync(): Promise<void> {
        this.syncLock.run();
    }

    private async fetchRemoteSongs(): Promise<void> {
        const userStore = getUserStore();
        if (!userStore.loggedIn) {
            throw new ApiError("user-not-logged-in", "Can't fetch remote songs, user not logged in");
        }
        if (isOffline()) {
            throw new ApiError("offline", "Can't fetch remote songs, app is offline in fetchRemoteSongs");
        }

        const songs = await this.remote.getAll();
        const songsInTransactionLog = await this.getSongsFromTransactionLog();
        await this.local.clear();

        await this.local.setSongs(songs);

        // Overrides songs that were in transactionLog
        await this.local.setSongs(songsInTransactionLog);
    }

    private async pushChanges(): Promise<void> {
        const userStore = getUserStore();
        if (!userStore.loggedIn) {
            throw new ApiError("user-not-logged-in", "Can't push remote changes, user not logged in");
        }
        if (isOffline()) {
            throw new ApiError("offline", "Can't push remote changes, app is offline in fetchRemoteSongs");
        }

        const transactionLog = this.getTransactionLog();
        await Promise.allSettled(
            Object.entries(transactionLog).map(async ([songId, songChange]) => {
                try {
                    switch (songChange.type) {
                        case "delete": {
                            await this.pushDeleteEvent(songId, songChange);
                            this.deleteSongTransactionLog(songId, songChange.timestamp);
                            break;
                        }
                        case "update": {
                            await this.pushUpdateEvent(songId, songChange);
                            this.deleteSongTransactionLog(songId, songChange.timestamp);
                            break;
                        }
                    }
                } catch (err) {
                    api.log.error(err);
                }
            })
        );

        const afterPushTransactionLog = this.getTransactionLog();
        if (Object.keys(afterPushTransactionLog).length > 0) {
            throw new Error("Error pushing songs");
        }
    }

    private deleteSongTransactionLog(songId: string, beforeTimestamp: number): void {
        const transactionLog = this.getTransactionLog();
        const transactionLogRecord = transactionLog[songId];
        const isOlderThanTimestamp = transactionLogRecord && transactionLogRecord.timestamp <= beforeTimestamp;
        if (isOlderThanTimestamp) {
            delete transactionLog[songId];
            api.localStorage.set("songTransactionLog", transactionLog);
        }
    }

    private updateTransactionLog(songId: string, record: SongTransactionLogRecord): void {
        const transactionLog = this.getTransactionLog();
        transactionLog[songId] = record;
        api.localStorage.set("songTransactionLog", transactionLog);
    }

    private updateSyncStatusInStore(status: SyncStatus) {
        const songStore = getSongStore();
        songStore.syncStatus = status;
    }

    private getTransactionLog(): SongTransactionLog {
        return api.localStorage.getObject<SongTransactionLog>("songTransactionLog") || {};
    }

    private async clearLocalData(): Promise<void> {
        await this.local.clear();
        this.clearTransactionLog();
    }

    private addTransactionLogRecord(id: string, type: "update" | "delete"): void {
        const timestamp = Date.now();
        this.updateTransactionLog(id, { type, timestamp });
        this.sync();
    }

    private clearTransactionLog(): void {
        api.localStorage.remove("songTransactionLog");
    }

    private shouldUpdateSong(song: Song | undefined, changeTimestamp: number): boolean {
        if (!song) return false;
        const lastEditTime = song.metadata.lastEditTime;
        return lastEditTime < changeTimestamp;
    }

    private async pushDeleteEvent(songId: string, songChange: ValueOf<SongTransactionLog>): Promise<void> {
        const remoteSong = await this.remote.get(songId);
        if (!remoteSong) {
            return;
        }
        const shouldDelete = this.shouldUpdateSong(remoteSong, songChange.timestamp);
        if (shouldDelete) {
            await this.remote.delete(songId);
        }
    }

    private async pushUpdateEvent(songId: string, songChange: ValueOf<SongTransactionLog>): Promise<void> {
        const currentSong = await this.local.get(songId);
        // A transactionLog exists, but the song has already been removed somehow
        if (!currentSong) {
            return;
        }
        const remoteSong = await this.remote.get(songId);
        if (!remoteSong) {
            await this.remote.set(songId, currentSong.toDTO());
        } else {
            if (this.shouldUpdateSong(remoteSong, songChange.timestamp)) {
                await this.remote.set(songId, currentSong.toDTO());
            }
        }
    }

    private async getSongsFromTransactionLog(): Promise<Song[]> {
        const transactionLog = this.getTransactionLog();
        const songIds = Object.keys(transactionLog);
        const songs = await Promise.all(
            songIds.map((id) => {
                return this.local.get(id);
            })
        );

        return filterTruthy(songs);
    }

    private createSyncTasks(): TasksLock<SyncStatus> {
        return new TasksLock<SyncStatus>(
            [
                async () => {
                    this.assertUserIsLoggedInAndOnline();

                    this.updateSyncStatusInStore(SyncStatus.IN_PROGRESS);
                    try {
                        await this.pushChanges();
                        return SyncStatus.DONE;
                    } catch (err) {
                        return SyncStatus.ERROR;
                    }
                },
                async (status) => {
                    this.assertUserIsLoggedInAndOnline();
                    await this.fetchRemoteSongs();
                    return status;
                },
                async (status) => {
                    const songStore = getSongStore();
                    await songStore.fetchSongs();
                    this.updateSyncStatusInStore(status);
                    return status;
                },
            ],
            (err: unknown) => {
                if (err instanceof SyncError) {
                    this.updateSyncStatusInStore(err.status);
                } else {
                    api.log.error(err);
                    this.updateSyncStatusInStore(SyncStatus.ERROR);
                }
            }
        );
    }

    private assertUserIsLoggedInAndOnline(): void {
        if (isOffline()) {
            throw new SyncError(SyncStatus.OFFLINE);
        }
        const userStore = getUserStore();
        if (!userStore.loggedIn) {
            throw new SyncError(SyncStatus.LOGGED_OUT);
        }
    }
}
