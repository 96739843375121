<template>
    <g :transform="transform">
        <rect width="10" height="3" x="-5" y="-10" rx="0" ry="0" />
        <line x1="-5" y1="-10" x2="-5" y2="2" stroke="black" stroke-width="1" />
        <line x1="5" y1="-10" x2="5" y2="2" stroke="black" stroke-width="1" />
    </g>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Point } from "../../../../models/point";

export default defineComponent({
    props: {
        from: {
            type: Point as PropType<Point>,
            required: true,
        },
    },
    computed: {
        transform(): string {
            const offset = new Point(0, 84);
            const originPoint = this.from.plus(offset);
            return `translate(${originPoint.x}, ${originPoint.y})`;
        },
    },
});
</script>

<style lang="scss" scoped>
path {
    stroke: var(--text-color);
    fill: var(--text-color);
}
</style>
