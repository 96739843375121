<template>
    <rect class="note-selected" v-if="showSelectedNote" :x="x" :y="y" rx="8" ry="8"></rect>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { TablaturePosition } from "../../../interfaces/tablature-position";
import type { Point } from "../../../models/point";
import { Geometry } from "../../../services/renderer/geometry";
import { EditorSelection } from "../../../services/editor-selection/editor-selection";

export default defineComponent({
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
    },
    computed: {
        selectedPosition(): TablaturePosition | undefined {
            return this.editorSelection.getPosition();
        },
        showSelectedNote(): boolean {
            return !this.editorSelection.hasRange() && Boolean(this.selectedPosition);
        },
        editorSelection(): EditorSelection {
            return this.$store.editor.selection as EditorSelection;
        },
        x(): number {
            if (!this.selectedPosition) {
                return 0;
            }
            return this.getPoint(this.selectedPosition.eventIndex, this.selectedPosition.stringIndex).x;
        },
        y(): number {
            if (!this.selectedPosition) {
                return 0;
            }
            return this.getPoint(this.selectedPosition.eventIndex, this.selectedPosition.stringIndex).y;
        },
    },
    methods: {
        getPoint(eventIndex: number, stringIndex = 0): Point {
            const position: TablaturePosition = { eventIndex: eventIndex, stringIndex };
            return this.geometry.tablaturePositionToPoint(position);
        },
    },
});
</script>

<style lang="scss" scoped>
.note-selected {
    visibility: "visible";
    fill: var(--primary-color);
    fill-opacity: 0.4;
    height: 18px;
    transform: translate(-9px, -9px);
    width: 18px;
}
</style>
