<template>
    <nav class="footer navbar fixed-bottom">
        <button
            class="btn btn-primary btn-action create-song"
            role="link"
            aria-label="Create a new song"
            @click="createNewSong"
            @keydown.enter="createNewSong"
            tabindex="0">
            <i class="bi bi-plus-lg"></i> <span class="d-none d-sm-flex">{{ $t("createNewSong") }}</span>
        </button>

        <div class="container">
            <div class="navigation-container d-flex gap-1">
                <router-link class="btn nav-link flex-column" :to="{ name: 'dashboard' }">
                    <i class="bi bi-music-note-list"></i><span>{{ $t("linkSongs") }}</span>
                </router-link>
                <router-link class="btn nav-link flex-column" :to="{ name: 'archive' }">
                    <i class="bi bi-archive"></i><span>{{ $t("linkArchive") }}</span>
                </router-link>
                <router-link class="btn nav-link flex-column" :to="{ name: 'trash' }">
                    <i class="bi bi-trash"></i><span>{{ $t("linkTrash") }}</span></router-link
                >
                <button v-if="shouldShowInstall" class="btn nav-link flex-column" @click="install">
                    <i class="bi bi-download"></i><span>{{ $t("linkInstall") }}</span>
                </button>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        showCreateSong: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        shouldShowInstall(): boolean {
            return this.$store.install.shouldShow();
        },
        canAddSong(): boolean {
            return this.$store.song.canAddSong;
        },
    },
    methods: {
        async createNewSong(): Promise<void> {
            if (!this.canAddSong) {
                this.$bus.emit("error", this.$t("songsLimitError"));
                return;
            }

            this.$router.push({
                name: "create",
            });
        },
        install(): void {
            this.$store.install.prompt();
        },
    },
});
</script>

<style lang="scss" scoped>
.footer {
    background-color: var(--background-color-secondary);
    border-top: solid 1px var(--gray-300);
    padding: 5px;

    @media (min-width: 1400px) {
        display: none;
    }

    .container {
        justify-content: center;

        .navigation-container {
            width: 250px;
            .nav-link {
                height: auto;
                padding: 5px 16px;
                gap: 0;
                flex: 1 1 0;
                width: 0;

                i {
                    font-size: 20px;
                }
            }
        }
    }

    .create-song {
        position: absolute;
        right: 20px;
        top: 0px;
        transform: translateY(calc(-100% - 10px));
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

        &:focus {
            box-shadow: inset 0 0 0 2px rgb(255 160 0 / 50%);
        }
    }
}
</style>
