<template>
    <div class="load-failure-container">
        <div class="error-message">
            <p class="title">{{ $t("failedToLoadSong") }}</p>
            <p class="subtext">{{ $t("failedToLoadSongReason") }}</p>
            <a href="/">{{ $t("backToSongs") }}</a>
        </div>
        <img class="error-image" src="/static/img/other/broken-guitar.png" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.load-failure-container {
    align-items: center;
    background-color: #002b45;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 10%;
    justify-content: center;
    overflow: hidden;

    .error-message {
        color: white;
        font-size: 1.2rem;
        text-align: center;

        .title {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.5;
        }

        a {
            color: var(--primary-color);
        }
    }

    .error-image {
        width: 400px;
        height: 400px;
    }
}
</style>
