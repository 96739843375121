<template>
    <g :transform="transform">
        <path fill="none" :d="curve"></path>
        <path class="arrow" stroke-width="0.2" :d="arrowPath"></path>
        <text class="tablature-text" x="-12" :y="textPosition" text-anchor="middle">{{ bendText }}</text>
    </g>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { BendDownModifier, NoteModifier } from "../../../../../common/note";
import { Point } from "../../../../models/point";

export default defineComponent({
    props: {
        from: {
            type: Point,
            required: true,
        },
        bend: {
            type: String as PropType<BendDownModifier | undefined>,
            required: true,
        },
        top: {
            type: Number as PropType<number>,
            required: true,
        },
    },
    computed: {
        transform(): string {
            const offset = new Point(0, -4);
            const originPoint = this.from.plus(offset);
            return `translate(${originPoint.x}, ${originPoint.y})`;
        },
        bendText(): string {
            if (this.bend === NoteModifier.halfBendDown) return "1/2";
            if (this.bend === NoteModifier.bendDown) return "1";
            if (this.bend === NoteModifier.quarterBendDown) return "1/4";
            if (this.bend === NoteModifier.doubleBendDown) return "2";
            else return "";
        },
        topDiff(): number {
            return this.top - this.from.y - 12;
        },
        arrowPath(): string {
            const arrowPoint = -2;
            const arrowBase = arrowPoint - 4;
            return `M0 ${arrowPoint} L-4 ${arrowBase} L4 ${arrowBase}Z`;
        },
        curve(): string {
            const curveTop = this.topDiff - 1;
            const curveBase = -3;
            return `M-12  ${curveTop} Q0  ${curveTop},0 ${curveBase}`;
        },
        textPosition(): number {
            return this.topDiff - 7;
        },
    },
});
</script>

<style lang="scss" scoped>
path {
    stroke: var(--text-color);
    &.arrow {
        fill: var(--text-color);
    }
}
</style>
