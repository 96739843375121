<template>
    <page-wrapper :showCreateSong="false">
        <main-header :songStatus="$t('songsGroupArchived')" @searchChange="onSearchChange"></main-header>
        <songs :searchValue="searchValue" :modes="[archivedStatus]"></songs>
    </page-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SongStatus } from "@common/song-status";
import Songs from "@components/songs/songs.vue";
import MainHeader from "@components/main-header/main-header.vue";
import PageWrapper from "@components/page-wrapper/page-wrapper.vue";

export default defineComponent({
    components: {
        "page-wrapper": PageWrapper,
        "main-header": MainHeader,
        songs: Songs,
    },
    data() {
        return {
            archivedStatus: SongStatus.Archived,
            searchValue: "",
        };
    },
    methods: {
        onSearchChange(value: string): void {
            this.searchValue = value;
        },
    },
});
</script>

<style lang="scss" scoped></style>
