<template>
    <div
        v-hide-on-scroll="'.workspace'"
        class="zoom-controls d-flex align-items-center"
        :class="{ unsupported: !isSupported }"
        :title="!isSupported ? unsupportedMessage : ''">
        <button
            class="btn bi bi-zoom-out zoom-control"
            :class="{ disabled: !allowZoomOut }"
            :title="$t('zoomOut')"
            @click="onZoomOut"
            @keydown.enter="onZoomOut"
            tabindex="0"></button>
        <button
            class="btn bi bi-zoom-in zoom-control"
            :class="{ disabled: !allowZoomIn }"
            :title="$t('zoomIn')"
            @click="onZoomIn"
            @keydown.enter="onZoomIn"
            tabindex="0"></button>
        <button
            class="btn zoom-value zoom-control"
            :class="{ disabled: !allowZoomReset }"
            :title="$t('zoomReset')"
            @click="onZoomReset"
            @keydown.enter="onZoomReset"
            tabindex="0">
            {{ zoomPercentage }}
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        isSupported(): boolean {
            return this.$store.zoom.isSupported;
        },
        allowZoomOut(): boolean {
            return this.isSupported && this.$store.zoom.value > this.$store.zoom.min;
        },
        allowZoomIn(): boolean {
            return this.isSupported && this.$store.zoom.value < this.$store.zoom.max;
        },
        allowZoomReset(): boolean {
            return this.isSupported && this.$store.zoom.value !== this.$store.zoom.default;
        },
        zoomPercentage(): string {
            return this.$store.zoom.getAsPercentage();
        },
        unsupportedMessage(): string {
            return this.$store.zoom.UNSUPPORTED_MESSAGE;
        },
    },
    methods: {
        onZoomOut(): void {
            this.$store.zoom.out();
        },
        onZoomIn(): void {
            this.$store.zoom.in();
        },
        onZoomReset(): void {
            this.$store.zoom.reset();
        },
    },
});
</script>

<style lang="scss" scoped>
.zoom-controls {
    background-color: rgba(250, 250, 250, 0.9);
    border: solid 1px rgba(201, 201, 201, 0.5);
    border-radius: 30px;
    position: absolute;
    right: 12px;
    top: 60px;
    overflow: hidden;
    user-select: none;
    z-index: -1;

    .zoom-control {
        background-color: rgb(0 0 0 / 5%);
        border-right: solid 1px rgb(201 201 201 / 50%) !important;
        border-radius: 0;
        padding: 10px 14px;

        &:hover {
            background-color: rgb(0 0 0 / 7%);
            cursor: pointer;
        }

        &:first-child {
            border-radius: 30px 0 0 30px;
        }

        &:last-child {
            border-radius: 0 30px 30px 0;
            border-right: none;
        }
    }

    .zoom-value {
        line-height: 40px;
        min-width: 68px;
        justify-content: center;
    }

    &.unsupported {
        &:hover {
            cursor: not-allowed;
        }
    }
}
</style>
