<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled, flat }"
        :title="tooltip"
        @click="onDeleteRequestConfirmation"
        @keydown.enter="onDeleteRequestConfirmation"
        tabindex="0">
        <i class="bi bi-trash"></i>
        <span v-if="!hideText">{{ $t("actionDelete") }}</span>
    </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { SongStatus } from "@common/song-status";
import { Song } from "../../../models/song/song";
import { SongActionOptions } from "../../../interfaces/song-actions";
import { isWhitespaceString } from "../../../utils/utils";
import { shouldHideAction } from "../../../utils/song-actions-utils";
import Swal from "sweetalert2";

export default defineComponent({
    name: "song-action-delete",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            actionInProgress: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("delete", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.actionInProgress || this.songActionOptions.disabled === true;
        },
        tooltip(): string {
            return this.$t("actionDelete");
        },
        songName(): string {
            return isWhitespaceString(this.song.title) ? this.$t("song") : this.song.title;
        },
        currentRoute(): string {
            return this.$router.currentRoute.value.name as string;
        },
    },
    methods: {
        async onDeleteRequestConfirmation(event: Event): Promise<void> {
            event.preventDefault();
            if (this.disabled) {
                return;
            }

            const result = await Swal.fire({
                title: this.$t("actionDeleteConfirmationTitle", { songName: this.songName }),
                text: this.$t("actionCannotBeUndone"),
                showCancelButton: true,
                cancelButtonText: this.$t("actionCancel"),
                confirmButtonText: this.$t("actionDelete"),
                customClass: {
                    confirmButton: "btn-danger",
                },
            });
            if (result.isConfirmed) {
                this.onDelete();
            }
        },
        async onDelete(): Promise<void> {
            this.actionInProgress = true;
            await this.$store.song.delete(this.song);
            this.actionInProgress = false;

            if (["song", "edit"].includes(this.currentRoute)) {
                this.$router.push({ name: this.getPreviousMainRouteName(SongStatus.Trashed) });
            }

            this.$bus.emit("info", this.$t("actionCompleteDelete", { songName: this.songName }));
        },
        getPreviousMainRouteName(previousSongStatus: SongStatus): string {
            if (previousSongStatus === SongStatus.Trashed) {
                return "trash";
            }

            if (previousSongStatus === SongStatus.Archived) {
                return "archive";
            }

            return "dashboard";
        },
    },
});
</script>
