<template>
    <div @touchmove="onTouchMove" class="editor-tablature" oncontextmenu="return false;">
        <div class="tuning-container">
            <tuning
                v-if="_geometry"
                :style="{ 'margin-top': tuningMargin }"
                :lineHeight="tuningLineHeight"
                :stringCount="lineCount"
                @tuning-update="updateTuning"
                :can-edit="canEdit && !isSelecting"
                :tuning="song.tuning || defaultTuning" />
        </div>
        <div class="tablature-container" @mouseleave="onMouseLeave">
            <tablature-component
                :tablature="tablature"
                :style="style"
                :canEdit="canEdit"
                @geometry-update="onGeometryUpdate"
                @pointerdown="onPointerDown"
                @pointermove="onPointerMove"
                @pointerup="onPointerUp"
                @pointerout="onPointerOut"
                @dblclick="onDoubleClick"
                @mousedown="onMousedown"
                :show-annotations="false"
                :selected-position="lastSelectedPosition?.eventIndex ?? 0"
                ref="tablature">
                <template v-if="_geometry">
                    <hover :geometry="geometry" :hoverPosition="hoverPosition"></hover>
                    <range-selection :geometry="geometry"></range-selection>
                    <note-selection :geometry="geometry"></note-selection>
                </template>

                <!-- Event Markers, for development purposes-->
                <!-- TODO: make this a feature flag-->
                <!-- <g
                    v-if="_geometry"
                    v-for="(event, index) in song.tablature.events"
                    :transform="getEventTransform(index)"
                >
                    <line x1="0" y1="-10" x2="0" y2="-2" style="stroke: rgb(0, 0, 0)" />
                </g> -->
            </tablature-component>

            <template v-if="svgHandler && _geometry">
                <event-annotation-editor
                    v-for="eventIndex in eventsWithAnnotation"
                    :geometry="geometry"
                    :key="eventIndex"
                    :eventIndex="eventIndex"
                    :tablature-editor="tablatureEditor as TablatureEditor"
                    :annotation="getAnnotation(eventIndex)"
                    :can-edit="canEdit && !isSelecting"
                    @update-annotation="updateAnnotation(eventIndex, $event)"
                    ref="event-annotations"></event-annotation-editor>
                <add-annotation-button
                    v-if="selectedPosition && showAddAnnotationButton"
                    @add-annotation="addEventAnnotation"
                    :geometry="geometry"
                    :position="indexToTextPoint(selectedPosition.eventIndex)"></add-annotation-button>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import TablatureEventComponent from "@components/tablature/event.vue";
import TablatureComponent from "@components/tablature/tablature.vue";
import { defineComponent, nextTick, PropType } from "vue";
import { EDITOR_TABLATURE_EXTRA_HEIGHT, EXTRA_ROWS_IN_TABLATURE } from "../../../common/constants";
import type { TuningOption } from "@common/types";
import type { TablatureEvent } from "../../../common/tablature-event";
import type { TablaturePosition } from "../../interfaces/tablature-position";
import { Point } from "../../models/point";
import { Song } from "../../models/song/song";
import { Tablature } from "../../models/song/tablature";
import type { EditorSelection } from "../../services/editor-selection/editor-selection";
import { PointerManager } from "../../services/input-manager/pointer-manager";
import { Geometry } from "../../services/renderer/geometry";
import { DEFAULT_TABLATURE_STYLE, TablatureStyle } from "../../services/renderer/tablature-style";
import { TablatureEditor } from "../../services/song-editor/tablature-editor";
import { SVGHandler } from "../../services/svg-handler";
import { pointToTransform } from "../../utils/utils";
import AddAnnotationButton from "./event-annotations/add-annotation-button.vue";
import EventAnnotationEditor from "./event-annotations/event-annotation-editor.vue";
import Hover from "./overlay/hover.vue";
import NoteSelection from "./overlay/note-selection.vue";
import RangeSelection from "./overlay/range-selection.vue";
import Tuning from "./tuning/tuning.vue";

export default defineComponent({
    props: {
        song: {
            type: Song,
            required: true,
        },
        canEdit: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
    },
    data() {
        return {
            svgHandler: undefined as SVGHandler | undefined,
            pointerUpBind: undefined as any,
            defaultTuning: ["E", "B", "G", "D", "A", "E"] as TuningOption[],
            hoverPosition: undefined as TablaturePosition | undefined,
            tablatureEditor: new TablatureEditor(this.song),
            style: {
                ...DEFAULT_TABLATURE_STYLE,
                paddingRight: 20,
            } as TablatureStyle,
            _geometry: undefined as Geometry | undefined,
        };
    },
    mounted() {
        const tablature = this.$refs.tablature as any;
        if (!tablature || !tablature.svg) throw new Error("Tablature SVG not found in editor");
        this.svgHandler = new SVGHandler(tablature.svg);
        // setTimeout(() => {
        //     // Removes focus of all recently generated text
        //     forceBlur();
        // });

        this.pointerUpBind = this.onPointerUpOutside;
        window.addEventListener("pointerup", this.pointerUpBind);
    },
    unmounted() {
        this.pointerManager.clearTouchHoldTimeout();
        window.removeEventListener("pointerup", this.pointerUpBind);
    },
    emits: {
        updateAnnotation: (_index: number, _value: string | undefined) => true,
    },
    components: {
        "tablature-event": TablatureEventComponent,
        hover: Hover,
        "note-selection": NoteSelection,
        "range-selection": RangeSelection,
        "event-annotation-editor": EventAnnotationEditor,
        "add-annotation-button": AddAnnotationButton,
        "tablature-component": TablatureComponent, // Conflict between component name and computed prop
        tuning: Tuning,
    },
    methods: {
        getAnnotation(index: number): string {
            const annotation = this.tablature.getAnnotation(index) || "";
            return annotation;
        },
        getEventTransform(index: number): string {
            const point = this.geometry.getEventPoint(index);
            return pointToTransform(point);
        },
        onTouchMove(payload: TouchEvent): void {
            this.pointerManager.onTouchMove(payload);
        },
        onMouseLeave(): void {
            this.setHoverPosition(undefined);
        },
        onDoubleClick(event: MouseEvent): void {
            const position = this.mouseEventToTablaturePosition(event);
            if (!this.canEdit || !position) {
                return;
            }
            this.pointerManager.onDoubleClick(position, this.lineCount);
        },
        // Using mousedown event to prevent text selection on double click.
        // Pointer Down always fires with a detail value of 1, therefore it cannot be used.
        onMousedown(event: MouseEvent): void {
            if (event.detail === 2) {
                event.preventDefault();
            }
        },
        onPointerDown(payload: PointerEvent): void {
            const position = this.mouseEventToTablaturePosition(payload);
            if (!this.canEdit) {
                return;
            }
            this.pointerManager.onPointerDown(payload, position);

            if (payload.pointerType !== "touch") {
                this.setHoverPosition(position);
            }
        },
        onPointerMove(payload: PointerEvent): void {
            const position = this.mouseEventToTablaturePosition(payload);
            if (!this.canEdit || !position) {
                return;
            }

            if (payload.pointerType !== "touch") {
                this.setHoverPosition(position);
            }

            this.pointerManager.onPointerMove(payload, position, this.maxSelectableEvent);
        },
        onPointerUp(payload: PointerEvent): void {
            if (!this.canEdit) {
                return;
            }

            const position = this.mouseEventToTablaturePosition(payload);
            this.pointerManager.onPointerUp(payload, position);
        },
        // The following variation of pointer up serves to complete range selections
        // when the pointer is released outside of the tablature.
        onPointerUpOutside(payload: PointerEvent): void {
            if (!this.canEdit || !this.editorSelection.inProgress) {
                return;
            }

            const position = this.mouseEventToTablaturePosition(payload);
            this.pointerManager.onPointerUp(payload, position);
        },
        onPointerOut(ev: PointerEvent): void {
            if (ev.pointerType === "touch") {
                this.pointerManager.onPointerOut();
            }
        },
        mouseEventToTablaturePosition(payload: MouseEvent): TablaturePosition | undefined {
            if (!this._geometry || !this.svgHandler) return undefined;
            const mousePoint = this.svgHandler.toSVGPoint(payload.clientX, payload.clientY);
            const hoverPosition = this.geometry.pointToTablaturePosition(mousePoint);
            return this.isValidPosition(hoverPosition) ? hoverPosition : undefined;
        },
        isValidPosition(position: TablaturePosition): boolean {
            const isValidEvent = position.eventIndex >= 0;
            const isValidString = position.stringIndex >= 0 && position.stringIndex < this.lineCount;
            return isValidEvent && isValidString;
        },
        updateTuning(tuning: TuningOption[]): void {
            this.$store.editor.markAsChanged();
            this.song.updateTuning(tuning);
        },
        setHoverPosition(position: TablaturePosition | undefined): void {
            if (position && position.eventIndex <= this.maxSelectableEvent) {
                this.hoverPosition = position;
            } else {
                this.hoverPosition = undefined;
            }
        },
        indexToTextPoint(index: number): Point {
            return this.geometry.tablaturePositionToPoint({
                eventIndex: index,
                stringIndex: 0,
            });
        },
        addEventAnnotation(): void {
            const eventIndex = this.selectedPosition?.eventIndex;
            if (eventIndex !== undefined) {
                this.$emit("updateAnnotation", eventIndex, "");
            }

            nextTick(() => {
                // This code relies on $refs returning the newly created element last
                const lastAnnotation = this.eventAnnotations[this.eventAnnotations.length - 1];
                if (!lastAnnotation) throw new Error("lastAnnotation not found in editor-tablature");
                lastAnnotation.focus();
            });
        },
        onGeometryUpdate(geometry: Geometry): void {
            this._geometry = geometry;
        },
        updateAnnotation(eventIndex: number, value: string | undefined): void {
            this.$emit("updateAnnotation", eventIndex, value);
        },
    },
    computed: {
        editorSelection(): EditorSelection {
            return this.$store.editor.selection as EditorSelection;
        },
        isSelecting(): boolean {
            return this.editorSelection.inProgress;
        },
        tablature(): Tablature {
            return this.song.tablature;
        },
        eventAnnotations(): Array<typeof EventAnnotationEditor> {
            return this.$refs["event-annotations"] as any;
        },
        maxSelectableEvent(): number {
            const maxEvent = Math.max(this.selectedPosition?.eventIndex || 0, this.tablature.trimmedEventsCount());
            return this.geometry.getMaxEvent(maxEvent, EXTRA_ROWS_IN_TABLATURE);
        },
        eventsWithAnnotation(): number[] {
            const eventsIndexes = [];
            for (let i = 0; i < this.events.length; i++) {
                const event = this.events[i];
                if (event?.annotation !== undefined) {
                    eventsIndexes.push(i);
                }
            }
            return eventsIndexes;
        },
        selectedPosition(): TablaturePosition | undefined {
            return this.$store.editor.selection.getPosition();
        },
        lastSelectedPosition(): TablaturePosition | undefined {
            return this.$store.editor.selection.getLastSelectedPosition();
        },
        canvasHeight(): number {
            // TODO: maxEvent logic is duplicate in tablature #407
            // Also, why is this using lastSelectedPosition instead of position?
            const maxEvent = Math.max(this.lastSelectedPosition?.eventIndex || 0, this.tablature.trimmedEventsCount());
            return this.geometry.getTotalHeight(maxEvent) + EDITOR_TABLATURE_EXTRA_HEIGHT;
        },
        geometry(): Geometry {
            if (!this._geometry) throw new Error("Geometry not available");
            return this._geometry as Geometry;
        },
        pointerManager(): PointerManager {
            return new PointerManager(this.$store.editor.selection as EditorSelection);
        },
        lineCount(): number {
            return this.tablature.lineCount;
        },
        svg(): SVGGraphicsElement {
            const tablature = this.$refs.tablature as any;
            if (!tablature || !tablature.svg) throw new Error("Tablature SVG not found in editor");
            return tablature.svg;
        },
        events(): Array<TablatureEvent | undefined> {
            return this.tablature.getAll();
        },
        tuningMargin(): string {
            const halfLineHeight = this.geometry.style.lineHeight / 2;
            const padding = this.geometry.style.paddingTop - halfLineHeight;
            return `${padding}px`;
        },
        tuningLineHeight(): number {
            return this.geometry.style.lineHeight;
        },
        showAddAnnotationButton(): boolean {
            const selectedPosition = this.selectedPosition;
            if (!selectedPosition) return false;
            if (this.$store.editor.selection.hasRange()) return false;

            const event = this.tablature.get(selectedPosition.eventIndex);

            const annotationExists = event && event.annotation !== undefined;
            return !annotationExists;
        },
    },
});
</script>

<style lang="scss" scoped>
.editor-tablature {
    position: relative;
    display: flex;
    width: 100%;

    .tuning-container {
        width: 20px;
    }

    .tablature-container {
        flex-grow: 1;
        position: relative;
    }
}
</style>
