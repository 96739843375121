<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container container-fluid">
            <i
                class="btn bi bi-arrow-left back"
                :title="$t('backToSongs')"
                @click="onNavigateBack"
                @keydown.enter="onNavigateBack"
                tabindex="0"></i>
            <div class="toolbar d-flex align-items-center gap-1">
                <zoom-controls></zoom-controls>

                <button
                    class="btn btn-primary"
                    :class="{ disabled: isCopying }"
                    :title="$t('copyToMyNotebook')"
                    @click="onCopySong"
                    @keydown.enter="onCopySong">
                    <i v-if="isCopying" class="spinner-border-overlay spinner-border-sm" role="status"></i>
                    <i class="bi bi-journal-arrow-down" :class="{ hide: isCopying }"></i>
                    <span>{{ $t("copyToMyNotebook") }}</span>
                </button>
                <user-dropdown-menu></user-dropdown-menu>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import ZoomControls from "@components/zoom-controls/zoom-controls.vue";
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";
import { defineComponent, PropType } from "vue";
import { api } from "../../../apis/api";
import type { Song } from "../../../models/song/song";

export default defineComponent({
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    data() {
        return {
            isCopying: false,
        };
    },
    components: {
        "zoom-controls": ZoomControls,
        "user-dropdown-menu": UserDropdownMenu,
    },
    methods: {
        onNavigateBack(): void {
            this.$router.push({ name: "dashboard" });
        },
        async onCopySong(): Promise<void> {
            this.isCopying = true;
            api.log.track("copySharedSong");

            const id = await this.$store.editor.copySharedSong();
            this.$router.push({ name: "song", query: { song: id } });
            this.isCopying = false;
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-primary);
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;

    .container {
        column-gap: 25px;
        flex-wrap: nowrap;
        position: relative;
        .enable-edition-button-wrapper {
            width: 160px;

            span {
                flex-grow: 1;
            }
        }
    }
}
</style>
