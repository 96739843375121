<template>
    <rect
        v-if="hoverPosition"
        class="event-hover"
        :x="x"
        :y="y"
        rx="8"
        ry="8"
        :height="height"
        :transform="transform"></rect>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { TablaturePosition } from "../../../interfaces/tablature-position";
import type { Point } from "../../../models/point";
import { Geometry } from "../../../services/renderer/geometry";

export default defineComponent({
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        hoverPosition: {
            type: Object as PropType<TablaturePosition>,
        },
    },
    computed: {
        transform(): string {
            return `translate(-8, -${this.lineHeight})`;
        },
        lineHeight(): number {
            return this.geometry.style.lineHeight;
        },
        x(): number {
            if (!this.hoverPosition) {
                return 0;
            }
            return this.getPoint(this.hoverPosition.eventIndex).x;
        },
        y(): number {
            if (!this.hoverPosition) {
                return 0;
            }
            return this.getPoint(this.hoverPosition.eventIndex).y;
        },
        height(): number {
            return this.lineHeight * (this.geometry.linesCount + 1);
        },
    },
    methods: {
        getPoint(eventIndex: number, stringIndex = 0): Point {
            const position: TablaturePosition = { eventIndex: eventIndex, stringIndex };
            return this.geometry.tablaturePositionToPoint(position);
        },
    },
});
</script>

<style lang="scss" scoped>
.event-hover {
    visibility: "visible";
    fill: var(--primary-color);
    fill-opacity: 0.3;
    width: 16px;
}
</style>
