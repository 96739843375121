import type { SongDTO } from "../../../common/dtos/song.dto";
import { Song } from "./song";

export type ParserInput = Omit<SongDTO, "version">;

export function parseSongDTO(id: string, originalSong: ParserInput): Song {
    // Any parsing regarding multiple versions must be done here

    originalSong.metadata.visibility = originalSong.metadata.visibility ?? "private";
    originalSong.metadata.labels = originalSong.metadata.labels ?? [];

    return new Song({
        ...originalSong,
        id,
    });
}
