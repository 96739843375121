<template>
    <div>
        <a
            class="btn user-dropdown-menu dropdown-toggle"
            :class="{
                'btn-hover-gray-light': useLightStyle,
                light: useLightStyle,
            }"
            href="#"
            data-bs-toggle="dropdown"
            :aria-label="$t('toggleUserMenu')">
            <div v-if="isLoggedIn">
                <i v-if="avatarUrl === ''" class="bi bi-person-circle"></i>
                <div v-else class="avatar-container">
                    <i v-if="isOffline" class="bi bi-person-circle"></i>
                    <img v-else class="avatar-image" :src="avatarUrl" />
                    <div class="promoted-sync-status">
                        <sync-status :onlyShowPromoted="true"></sync-status>
                    </div>
                </div>
            </div>
            <i v-else class="bi bi-cloud-slash-fill"></i
        ></a>
        <ul class="dropdown-menu dropdown-menu-end" data-bs-popper="static">
            <li v-if="isLoggedIn">
                <div class="dropdown-header email d-flex gap-2" :title="user?.email ?? undefined">
                    <i class="bi bi-envelope-at"></i><span class="user-email">{{ user?.email }}</span>
                </div>
                <div class="dropdown-header sync-status">
                    <sync-status :showText="true"></sync-status>
                </div>
            </li>
            <li v-else>
                <a
                    class="btn login-button gap-1"
                    :class="{ 'btn-hover-gray-light': useLightStyle }"
                    @click="login"
                    @keydown.enter="login"
                    tabindex="0">
                    <div>
                        <i class="bi bi-person-circle"></i>
                        {{ $t("logIn") }}
                    </div>
                    <div class="login-features">
                        {{ $t("syncStatusLoggedOut") }}
                    </div>
                </a>
            </li>
            <hr class="dropdown-divider" />

            <li>
                <router-link class="btn" :class="{ 'btn-hover-gray-light': useLightStyle }" :to="{ name: 'settings' }"
                    ><i class="bi bi-gear"></i> {{ $t("linkSettings") }}</router-link
                >
            </li>
            <li>
                <a
                    class="btn"
                    :class="{ 'btn-hover-gray-light': useLightStyle }"
                    href="https://my-guitar-tabs.com/contact"
                    ><i class="bi bi-chat-square-text"></i> {{ $t("linkContactUs") }}</a
                >
            </li>
            <li v-show="isLoggedIn">
                <a class="btn" :class="{ 'btn-hover-gray-light': useLightStyle }" @click="logout"
                    ><i class="bi bi-box-arrow-left"></i> {{ $t("logOut") }}</a
                >
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { User } from "firebase/auth";
import { defineComponent } from "vue";
import { isOffline } from "../../utils/is-offline";
import SyncStatus from "../sync-status/sync-status.vue";

export default defineComponent({
    props: {
        useLightStyle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            securedData: "",
        };
    },
    components: {
        "sync-status": SyncStatus,
    },
    computed: {
        user(): User | undefined {
            return this.$store.user.user;
        },
        isLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        avatarUrl(): string {
            return this.$store.user.user?.providerData[0]?.photoURL ?? "";
        },
        isOffline(): boolean {
            return isOffline();
        },
    },
    methods: {
        async logout(): Promise<void> {
            await this.$store.user.logout();
            await this.$router.push({ name: "dashboard" });
        },
        async login(): Promise<void> {
            await this.$router.push({
                name: "signin",
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.user-dropdown-menu {
    &.btn {
        &.light {
            border-top-right-radius: 22px;
            border-bottom-right-radius: 22px;
        }
    }

    &.show {
        background-color: var(--gray-200);
    }

    .avatar-container {
        position: relative;
        width: 16px;

        .avatar-image {
            width: 32px;
            height: 32px;
            margin: 0 -8px;
            border-radius: 50%;
        }
    }
}

.dropdown-menu {
    min-width: 220px;
    max-width: 290px;

    .login-button {
        display: flex;
        flex-direction: column;
        height: auto;

        .login-features {
            font-size: 11px;
            white-space: normal;
        }
    }

    .dropdown-header {
        cursor: default;
        padding-bottom: 0;

        .user-email {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
