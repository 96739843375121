<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled, flat }"
        :title="tooltip"
        @click="onShareDialogDisplay"
        @keydown.enter="onShareDialogDisplay"
        tabindex="0">
        <i class="bi" :class="{ 'bi-share-fill': isPublic, 'bi-share': !isPublic }"></i>
        <span v-if="!hideText">{{ $t("actionShare") }}</span>
    </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../models/song/song";
import { SongActionOptions } from "../../../interfaces/song-actions";
import { isWhitespaceString } from "../../../utils/utils";
import { api } from "../../../apis/api";
import { shouldHideAction } from "../../../utils/song-actions-utils";
import { SongVisibility } from "@common/song-metadata";
import Swal from "sweetalert2";

export default defineComponent({
    name: "song-action-share",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            actionInProgress: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("share", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.actionInProgress || this.songActionOptions.disabled === true;
        },
        isPublic(): boolean {
            return this.song.metadata.visibility === "public";
        },
        tooltip(): string {
            return this.isPublic
                ? `${this.$t("visibilityPublic")}\n${this.$t("visibilityPublicDescription")}`
                : `${this.$t("visibilityPrivate")}\n${this.$t("visibilityPrivateDescription")}`;
        },
        songName(): string {
            return isWhitespaceString(this.song.title) ? this.$t("song") : this.song.title;
        },
        isLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
    },
    methods: {
        // Sharing features
        async onShareDialogDisplay(event: Event): Promise<void> {
            event.preventDefault();
            if (this.disabled) {
                return;
            }

            if (!this.isLoggedIn) {
                await this.$router.push({
                    name: "signin",
                });
                return;
            }

            const uid = this.$store.user.user?.uid;
            if (!uid) throw new Error("UID for user not found when sharing");
            const shareUrl = `${origin}/share?song=${this.song.id}&uid=${uid}`;

            const html = `<div class="share-form">
                <div id="visibility-row" class="form-row">
                    <div class="visibility-icon">
                        <div id="visibility-icon-spinner" class="spinner-border-overlay text-secondary" role="status"></div>
                        <i id="visibility-icon-shared" class="bi bi-people-fill"></i>
                        <i id="visibility-icon-private" class="bi bi-lock-fill"></i>
                    </div>
                    <div class="visibility-value">
                        <select id="visibility-select" class="form-select">
                            <option value="public">${this.$t("visibilityPublic")}</option>
                            <option value="private">${this.$t("visibilityPrivate")}</option>
                        </select>
                        <div class="visibility-description">
                            <span id="visibility-description-shared">${this.$t("visibilityPublicDescription")}</span>
                            <span id="visibility-description-private">${this.$t("visibilityPrivateDescription")}</span>
                        </div>
                    </div>
                </div>
                <div id="link-row" class="form-row">
                    <input id="share-link" class="form-control" type="text" readonly value="${shareUrl}">
                    <button id="copy-link" class="btn btn-primary">
                        <i class="bi bi-link"></i><span>${this.$t("copyLink")}</span>
                    </button>
                </div>
            </div>`;

            await Swal.fire({
                title: this.$t("shareSong"),
                html: html,
                inputValue: 1,
                inputPlaceholder: this.$t("visibilityPublic"),
                confirmButtonText: this.$t("done"),
                customClass: {
                    confirmButton: "btn-secondary",
                },
                showClass: {
                    popup: "fade-in-animation",
                },
                hideClass: {
                    popup: "fade-out-animation",
                },
                didRender: this.onShareDialogRender,
            });
        },

        onShareDialogRender(_popup: HTMLElement): void {
            setTimeout(() => {
                this.autoselectShareLink();
            });

            this.refreshShareModal();
            this.updateShareLoading(false);
            const copyLinkButton = document.getElementById("copy-link") as HTMLButtonElement;
            copyLinkButton.addEventListener("click", this.onCopyLinkClick);

            const visibilitySelect = document.getElementById("visibility-select") as HTMLSelectElement;
            visibilitySelect.addEventListener("change", this.onVisibilityChange);
            visibilitySelect.dispatchEvent(new Event("change"));
        },
        autoselectShareLink(): void {
            const input = document.getElementById("share-link") as HTMLInputElement;
            if (!input) {
                return;
            }
            input.focus();
            input.setSelectionRange(0, input.value.length);
        },

        // Copy link
        onCopyLinkClick(): void {
            this.autoselectShareLink();
            try {
                document.execCommand("copy");

                const copyLinkButton = document.getElementById("copy-link") as HTMLButtonElement;
                copyLinkButton.innerHTML = `<i class="bi bi-check"></i><span>${this.$t("copiedLink")}</span>`;
            } catch (error) {
                api.log.error(error);
            }
        },

        async onVisibilityChange(): Promise<void> {
            if (this.actionInProgress) {
                return;
            }

            const visibilitySelect = document.getElementById("visibility-select") as HTMLSelectElement;
            if (!visibilitySelect) {
                return;
            }

            const setPublic = visibilitySelect.value === "public";
            const hasShareChanged = this.isPublic !== setPublic;
            if (!hasShareChanged) {
                return;
            }

            if (setPublic) {
                api.log.track("shareSong");
            }

            const newVisibility = visibilitySelect.value as SongVisibility;

            this.actionInProgress = true;
            this.updateShareLoading(true);

            const previousVisibility = this.song.metadata.visibility;
            try {
                this.song.metadata.visibility = newVisibility;
                this.refreshShareModal();
                await this.$store.song.updateVisibility(this.song.id, newVisibility);
            } catch (error: unknown) {
                console.error(error);
                this.$bus.emit("error", "Song visibility could not be updated");
                this.song.metadata.visibility = previousVisibility;
                this.refreshShareModal();
            } finally {
                this.updateShareLoading(false);
                this.actionInProgress = false;
            }
        },
        refreshShareModal(): void {
            const visibilityIconShared = document.getElementById("visibility-icon-shared");
            const visibilityIconPrivate = document.getElementById("visibility-icon-private");
            const visibilityDescriptionShared = document.getElementById("visibility-description-shared");
            const visibilityDescriptionPrivate = document.getElementById("visibility-description-private");
            const linkrow = document.getElementById("link-row");
            if (
                !visibilityIconShared ||
                !visibilityIconPrivate ||
                !visibilityDescriptionShared ||
                !visibilityDescriptionPrivate ||
                !linkrow
            ) {
                return;
            }

            if (this.isPublic) {
                visibilityIconShared.style.display = "block";
                visibilityIconPrivate.style.display = "none";
                visibilityDescriptionShared.style.display = "block";
                visibilityDescriptionPrivate.style.display = "none";
                linkrow.style.display = "flex";
            } else {
                visibilityIconShared.style.display = "none";
                visibilityIconPrivate.style.display = "block";
                visibilityDescriptionShared.style.display = "none";
                visibilityDescriptionPrivate.style.display = "block";
                linkrow.style.display = "none";
            }
        },
        updateShareLoading(isLoading = false): void {
            const visibilitySelect = document.getElementById("visibility-select") as HTMLSelectElement;
            const visibilitySpinner = document.getElementById("visibility-icon-spinner");
            if (!visibilitySelect || !visibilitySpinner) {
                return;
            }

            if (isLoading) {
                visibilitySelect.disabled = true;
                visibilitySpinner.style.display = "block";
            } else {
                visibilitySelect.disabled = false;
                visibilitySpinner.style.display = "none";
            }
        },
    },
});
</script>

<style lang="scss">
.share-form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .form-row {
        align-items: flex-start;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: center;
        padding: 15px 20px;
        width: 100%;

        &#visibility-row {
            &:hover {
                background-color: var(--gray-50);
            }
        }

        &#link-row {
            position: relative;

            input {
                padding-right: 130px;
            }

            button {
                border-radius: 5px;
                height: 42px;
                position: absolute;
                right: 21px;
                top: 16px;
            }
        }
    }

    .visibility-icon {
        align-items: center;
        border-radius: 25px;
        background-color: var(--secondary-color-50);
        display: flex;
        height: 40px;
        justify-content: center;
        margin-top: -3px;
        width: 40px;
        min-width: 40px;
    }

    .visibility-value {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    #visibility-select {
        flex-grow: 1;
        height: 35px !important;
        width: 100%;
    }

    .visibility-description {
        font-size: 0.8em;
        padding: 5px;
        text-align: left;
    }

    #share-link {
        flex-grow: 1;
        font-size: 0.8em;
        height: 44px !important;
        min-width: 0;
        width: auto;
    }

    #copy-link {
        min-width: 120px;
        width: 120px;
    }

    .spinner-border-overlay {
        color: #cdcee4 !important;
        height: 40px;
        margin-left: 0;
        margin-top: -1px;
        width: 40px;
    }
}
</style>
