<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled, flat }"
        :title="tooltip"
        @click="onToggleArchive"
        @keydown.enter="onToggleArchive"
        tabindex="0">
        <i class="bi" :class="{ 'bi bi-box-arrow-up': isArchived, 'bi-archive': !isArchived }"></i>
        <span v-if="!hideText">{{ text }}</span>
    </li>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { SongStatus } from "@common/song-status";
import { Song } from "../../../models/song/song";
import { api } from "../../../apis/api";
import { SongActionOptions } from "../../../interfaces/song-actions";
import { isWhitespaceString } from "../../../utils/utils";
import { shouldHideAction } from "../../../utils/song-actions-utils";

export default defineComponent({
    name: "song-action-archive",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            actionInProgress: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("archive", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.actionInProgress || this.songActionOptions.disabled === true;
        },
        isArchived(): boolean {
            return this.song.isArchived();
        },
        isInTrash(): boolean {
            return this.song.isInTrash();
        },
        text(): string {
            return this.isArchived ? this.$t("actionMoveToSongs") : this.$t("actionArchive");
        },
        tooltip(): string {
            return this.text;
        },
        songName(): string {
            return isWhitespaceString(this.song.title) ? this.$t("song") : this.song.title;
        },
    },
    methods: {
        async onToggleArchive(event: Event): Promise<void> {
            event.preventDefault();
            if (this.disabled) {
                return;
            }

            api.log.track("toggleArchive");
            const updatedSong = this.song.copy();
            updatedSong.status = this.song.isArchived() ? SongStatus.Default : SongStatus.Archived;

            this.actionInProgress = true;
            await this.$store.song.update(updatedSong);
            this.actionInProgress = false;

            const messageKey = updatedSong.isArchived() ? "actionCompleteArchive" : "actionCompleteMoveToSongs";
            this.$bus.emit("info", this.$t(messageKey, { songName: this.songName }));
        },
    },
});
</script>
