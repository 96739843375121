<template>
    <main class="container songs">
        <loader :status="loadStatus" :loading-message="$t('loadingSongs')" :error-message="$t('failedToLoadSongs')">
            <login-warning></login-warning>

            <div v-if="showCreateSong" class="create-song d-flex justify-content-center d-none d-xxl-flex">
                <router-link
                    tag="button"
                    to="/create"
                    class="btn btn-primary"
                    @keydown.enter="createSong"
                    tabindex="0"
                    aria-label="Create New Song">
                    <i class="bi bi-plus-lg"></i>
                    {{ $t("createNewSong") }}
                </router-link>
            </div>

            <songs-summary
                :searchValue="searchValue"
                :resultsCount="resultsCount"
                :showCreateSong="showCreateSong"></songs-summary>

            <div v-for="mode in modesWithContent" class="mode-container" :key="mode">
                <div v-if="modesWithContent.length > 1" class="mode-name">{{ $t("songsGroup" + mode) }}</div>
                <template v-if="showGridMode">
                    <song-preview-group :songs="getSongs(mode)"></song-preview-group>
                </template>
                <template v-else>
                    <song-list-group :songs="getSongs(mode)"></song-list-group>
                </template>
            </div>
        </loader>
    </main>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { SongStatus } from "@common/song-status";
import { Song } from "../../models/song/song";
import { LoadingStatus } from "../../../common/constants";
import Loader from "@components/loader/loader.vue";
import LoginWarning from "@components/login-warning/login-warning.vue";
import SongsSummary from "./songs-summary/songs-summary.vue";
import SongPreviewGroup from "./song-preview/song-preview-group.vue";
import SongListGroup from "./song-list/song-list-group.vue";

export default defineComponent({
    props: {
        searchValue: {
            type: String,
            default: "",
        },
        modes: {
            type: Array as PropType<SongStatus[]>,
            default: [SongStatus.Default],
        },
        showCreateSong: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        loader: Loader,
        "login-warning": LoginWarning,
        "songs-summary": SongsSummary,
        "song-list-group": SongListGroup,
        "song-preview-group": SongPreviewGroup,
    },
    computed: {
        showGridMode(): boolean {
            return this.$store.config.showGridMode;
        },
        resultsCount(): number {
            return this.modesWithContent.reduce((acc, mode) => acc + this.getSongs(mode).length, 0);
        },
        modesWithContent(): SongStatus[] {
            return this.modes.filter((mode) => this.modeHasSongs(mode));
        },
        loadStatus(): LoadingStatus {
            return this.$store.song.loadStatus;
        },
    },
    methods: {
        modeHasSongs(mode: SongStatus): boolean {
            return this.getSongs(mode).length > 0;
        },
        getSongs(mode: SongStatus): Song[] {
            if (this.searchValue === "") return this.$store.song.getAll(mode);
            return this.$store.song.getAll(mode, this.searchValue);
        },
        createSong(event: KeyboardEvent): void {
            const button = event.target as HTMLButtonElement;
            button.click();
        },
    },
});
</script>

<style lang="scss" scoped>
.songs {
    padding-bottom: 90px;
    padding-top: 90px;

    .create-song {
        margin-bottom: 30px;
    }

    .mode-name {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
        margin: 15px;
        margin-top: 0;
        filter: grayscale(1);
    }
}
</style>
