import { LoadingStatus, SyncStatus } from "@common/constants";
import type { User } from "firebase/auth";
import { defineStore } from "pinia";
import Swal from "sweetalert2";
import { api } from "../apis/api";
import { getSongStore } from "./song.store";

export const getUserStore = defineStore("user", {
    state() {
        return {
            loadStatus: LoadingStatus.UNINITIALIZED,
            user: undefined as User | undefined,
            swReload: undefined as (() => Promise<void>) | undefined,
            needReload: false,
        };
    },
    getters: {
        loggedIn(): boolean {
            return Boolean(this.user);
        },
        authProvider(): string | undefined {
            return this.user?.providerData[0]?.providerId;
        },
    },
    actions: {
        async logout(): Promise<void> {
            if (api.syncApi.haveUnsyncedSongs()) {
                const result = await Swal.fire({
                    title: this.$t("actionDiscardAndLogoutTitle"),
                    text: this.$t("actionDiscardAndLogoutText"),
                    showCancelButton: true,
                    cancelButtonText: this.$t("actionCancel"),
                    confirmButtonText: this.$t("actionDiscardAndLogout"),
                    customClass: {
                        confirmButton: "btn-danger",
                    },
                    icon: "warning",
                });
                if (!result.isConfirmed) {
                    return;
                }
            }

            await api.auth.logout();
            await this.refreshCurrentUser();
            await api.syncApi.onLogout();
            localStorage.removeItem("dismissedLoginWarning");
            this.$bus.emit("info", this.$t("loggedOutConfirmation"));
        },

        async refreshCurrentUser(): Promise<void> {
            this.loadStatus =
                this.loadStatus === LoadingStatus.UNINITIALIZED ? LoadingStatus.LOADING : LoadingStatus.RELOADING;
            try {
                this.user = await api.auth.getCurrentUser();
                this.loadStatus = LoadingStatus.READY;
            } catch (error: unknown) {
                console.error(error);
                this.$bus.emit("error", "User could not be loaded");
                this.loadStatus = LoadingStatus.ERROR;
            }

            const songStore = getSongStore();
            if (!this.loggedIn) {
                songStore.syncStatus = SyncStatus.LOGGED_OUT;
            }
        },
        async reload(): Promise<void> {
            if (!this.swReload) {
                throw new Error("Reload function not available");
            }
            await this.swReload();
        },
    },
});

export type UserStore = ReturnType<typeof getUserStore>;
