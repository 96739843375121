<template>
    <TransitionGroup name="list" tag="div" class="list-group w-auto">
        <song-list-item v-for="song in songs" :key="song.id" :song="song"></song-list-item>
    </TransitionGroup>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import SongListItem from "./song-list-item.vue";
import { Song } from "../../../models/song/song";

export default defineComponent({
    props: {
        songs: {
            type: Array as PropType<Song[]>,
            default: [],
        },
    },
    components: {
        "song-list-item": SongListItem,
    },
});
</script>

<style lang="scss" scoped>
.list-group {
    padding-bottom: 80px;
}
</style>
