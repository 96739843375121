<template>
    <song-action-edit
        v-if="includedActions.includes('edit')"
        :song="song"
        :songActionOptions="songActionOptions('edit')"
        :hideText="hideText"></song-action-edit>
    <song-action-share
        v-if="includedActions.includes('share')"
        :song="song"
        :songActionOptions="songActionOptions('share')"
        :hideText="hideText"></song-action-share>
    <song-action-pin
        v-if="includedActions.includes('pin')"
        :song="song"
        :songActionOptions="songActionOptions('pin')"
        :hideText="hideText"></song-action-pin>
    <song-action-archive
        v-if="includedActions.includes('archive')"
        :song="song"
        :songActionOptions="songActionOptions('archive')"
        :hideText="hideText"></song-action-archive>
    <song-action-copy
        v-if="includedActions.includes('copy')"
        :song="song"
        :songActionOptions="songActionOptions('copy')"
        :hideText="hideText"></song-action-copy>
    <song-action-trash
        v-if="includedActions.includes('trash')"
        :song="song"
        :songActionOptions="songActionOptions('trash')"
        :hideText="hideText"></song-action-trash>
    <song-action-delete
        v-if="includedActions.includes('delete')"
        :song="song"
        :songActionOptions="songActionOptions('delete')"
        :hideText="hideText"></song-action-delete>
</template>

<script lang="ts">
import SongActionEdit from "./actions/song-action-edit.vue";
import SongActionPin from "./actions/song-action-pin.vue";
import SongActionCopy from "./actions/song-action-copy.vue";
import SongActionArchive from "./actions/song-action-archive.vue";
import SongActionShare from "./actions/song-action-share.vue";
import SongActionTrash from "./actions/song-action-trash.vue";
import SongActionDelete from "./actions/song-action-delete.vue";
import { defineComponent, PropType } from "vue";
import { Song } from "../../models/song/song";
import { SongActionOptions, SongActionsType } from "../../interfaces/song-actions";

export default defineComponent({
    name: "song-actions",
    props: {
        // Some actions need the song information.
        song: {
            type: Object as PropType<Song>,
            required: true,
        },

        // Override global options for specific actions
        songActionsOptions: {
            type: Object as PropType<Record<SongActionsType, SongActionOptions>>,
            default: () => ({}),
        },

        includedActions: {
            type: Array as PropType<string[]>,
            default: () => [],
        },

        hideText: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        "song-action-edit": SongActionEdit,
        "song-action-pin": SongActionPin,
        "song-action-copy": SongActionCopy,
        "song-action-archive": SongActionArchive,
        "song-action-share": SongActionShare,
        "song-action-trash": SongActionTrash,
        "song-action-delete": SongActionDelete,
    },
    methods: {
        songActionOptions(action: SongActionsType): SongActionOptions {
            return this.songActionsOptions[action] ?? {};
        },
    },
});
</script>
