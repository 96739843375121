import type { ChordModifier } from "../../../../../common/tablature-event";
import type { NoteModifier } from "../../../../../common/note";
import type { EditorSelection } from "../../../editor-selection/editor-selection";
import { EditorCommand } from "../editor-command";
import { UpdateModifierCommand } from "./update-modifier";

export class ApplyModifierSuggestion extends EditorCommand {
    private modifier: NoteModifier | ChordModifier;

    constructor({ modifier }: { modifier: NoteModifier | ChordModifier; selection?: EditorSelection }) {
        super();
        this.modifier = modifier;
    }

    public async onExecute(): Promise<void> {
        const previousPosition = this.editorSelection.previousPosition;

        if (previousPosition) {
            const updateNoteModifier = new UpdateModifierCommand({
                modifier: this.modifier,
                position: previousPosition,
            });

            await updateNoteModifier.execute({
                song: this.song,
                editorSelection: this.editorSelection,
            });
        }
    }
}
