<template>
    <svg left="0" width="100%" :height="heightStyle" xmlns="http://www.w3.org/2000/svg" ref="svg">
        <stave :geometry="geometry" :rows="rowsCount" :canEdit="canEdit"></stave>
        <g>
            <g v-for="(event, index) in events" :transform="getEventTransform(index)">
                <tablature-event
                    v-if="event"
                    :show-annotation="showAnnotations"
                    :key="index"
                    :geometry="geometry"
                    :tablature="tablature"
                    :event="event"
                    :eventIndex="index"></tablature-event>
            </g>

            <end-line v-if="!canEdit" :geometry="geometry" :rowsCount="rowsCount" />
        </g>
        <g>
            <slot></slot>
        </g>
    </svg>
</template>

<script lang="ts">
import TablatureEventComponent from "@components/tablature/event.vue";
import { PropType, defineComponent } from "vue";
import { EXTRA_ROWS_IN_TABLATURE } from "../../../common/constants";
import type { TablatureEvent } from "@common/tablature-event";
import type { Point } from "../../models/point";
import { Tablature } from "../../models/song/tablature";
import { Geometry } from "../../services/renderer/geometry";
import { DEFAULT_TABLATURE_STYLE, TablatureStyle } from "../../services/renderer/tablature-style";
import { pointToTransform } from "../../utils/utils";
import EndLine from "./bar-line/end-line.vue";
import Stave from "./stave.vue";

export default defineComponent({
    props: {
        style: {
            type: Object as PropType<TablatureStyle>,
            default: DEFAULT_TABLATURE_STYLE,
        },
        tablature: {
            type: Tablature,
            required: true,
        },
        showAnnotations: {
            type: Boolean,
            default: true,
        },
        toEvent: {
            type: Number,
        },
        selectedPosition: {
            type: Number,
            default: 0,
        },
        canEdit: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    components: {
        stave: Stave,
        "tablature-event": TablatureEventComponent,
        "end-line": EndLine,
    },
    emits: {
        geometryUpdate: (_geometry: Geometry) => true,
    },
    data() {
        return {
            resizeBind: undefined as any, // Intermediary bind used to properly remove event.
            resizeObserver: undefined as ResizeObserver | undefined,
            _geometry: new Geometry(),
        };
    },
    mounted(): void {
        this._geometry.style = this.style;

        this._geometry.linesCount = this.tablature.lineCount;
        this._geometry.width = this.svg.clientWidth ?? 0;
        this.$emit("geometryUpdate", this.geometry);

        this.resizeBind = this.onResize;
        this.resizeObserver = new ResizeObserver(this.resizeBind);
        this.resizeObserver.observe(this.svg);
    },
    unmounted(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    computed: {
        geometry(): Geometry {
            return this._geometry as Geometry;
        },
        rowsCount(): number {
            const maxEvent = Math.max(this.selectedPosition, this.tablature.trimmedEventsCount());
            const extraRows = this.canEdit ? EXTRA_ROWS_IN_TABLATURE : 0;
            return this.geometry.getRowCount(maxEvent, extraRows);
        },
        events(): Array<TablatureEvent | undefined> {
            if (this.toEvent) {
                return this.tablature.getEvents(0, this.toEvent); // TODO: this should not be needed for partial song
            } else return this.tablature.getAll();
        },
        svg(): SVGGraphicsElement {
            return this.$refs.svg as SVGGraphicsElement;
        },
        height(): number {
            const maxEvent = Math.max(this.selectedPosition, this.tablature.trimmedEventsCount());
            return this.geometry.getTotalHeight(maxEvent);
        },
        heightStyle(): string {
            return `${this.height}px`;
        },
    },
    methods: {
        getEventTransform(index: number): string {
            const point = this.getEventPoint(index);
            return pointToTransform(point);
        },
        getEventPoint(index: number): Point {
            return this.geometry.getEventPoint(index);
        },
        onResize(): void {
            const svgWidth = this.svg.clientWidth ?? 0;
            if (this._geometry.width !== svgWidth) {
                // This setTimeout is to avoid "ResizeObserver loop completed with undelivered notifications." Error
                setTimeout(() => {
                    this._geometry.width = svgWidth;
                    this.$emit("geometryUpdate", this.geometry);
                });
            }
        },
    },
});
</script>

<style lang="scss" scoped>
svg {
    outline: none;
}
</style>
